import React, { useState, useEffect } from 'react';
import './BodyContainer.scss';
import StickyInfoBar from 'components/StickyInfoBar/phx/StickyInfoBar';
import { useConfig } from 'hook/config.hook';

function BodyContainer({
  children,
  className,
  type,
  datatestid,
  style,
  showStickyInfoBar = true,
  overlayClassName = '',
  ...props
}) {
  const { config } = useConfig();
  const [isScrolling, setIsScrolling] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const testid = datatestid ? `${datatestid}-body` : null;
  let tempClass = `BodyContainer`;
  tempClass = className ? `${tempClass} ${className}` : tempClass;
  tempClass = type ? `${tempClass} ${type}` : tempClass;

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const pageHeight = document.documentElement.scrollHeight;

    if (scrollPosition >= pageHeight - 10) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
      setIsScrolling(true);
      clearTimeout(window.scrollTimeout);
      window.scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`width100 ${overlayClassName}`}>
      <div
        className={tempClass}
        data-testid={testid}
        id={props.id}
        style={style}
      >
        <div id="main" className="BodyMainContainer">
          {children}
        </div>
      </div>
      {!isAtBottom && showStickyInfoBar && config?.showStickyBarPhx && (
        <StickyInfoBar scrollStates={isScrolling} />
      )}
    </div>
  );
}

export { BodyContainer };

import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  FATAL_ERROR,
  OCF_LOGIN_REQUEST,
  OCF_LOGIN_SUCCESS,
  OCF_LOGOUT,
  ACTIVATE_AUTOMATIC_UPDATE_MODAL,
  QUIT_AUTOMATIC_UPDATE_MODAL,
  ACTIVATE_SESSION_TIMEOUT_MODAL,
  QUIT_SESSION_TIMEOUT_MODAL,
  PUBLIC_LOGIN_REQUEST,
  PUBLIC_LOGIN_SUCCESS,
  TOGGLE_OFFLINE_MODE,
  FORCE_HIDE_MENU_BAR,
  SET_INITIAL_REFERRER,
  LOGIN_REQUEST_RESET_MIGRATE_USER,
} from 'types/app.constants';
import { isApiKeySessionValid } from 'helpers/session';
import { isSessionValid } from 'helpers/sessionLocalStorageReader.helper';

const getInitialState = () => {
  const initialState = {
    loggingIn: false,
    loggingInLevel: null,
    loggedIn: isSessionValid(),
    requireUserSuccess: isSessionValid(),
    fullyInitialized: false,
    firstUserSuccessDetected: false,
    firstConfigSuccessDetected: false,
    requireCreditProfileSuccess: isSessionValid(),
    firstCreditProfileSuccessDetected: false,
    user: null,
    email: null,
    loginResponse: null,
    fatalError: null,
    ocfLoggingIn: false,
    // ocf sessions cannot be shared between tabs because the ocf and session
    // is linked
    ocfLoggedIn: false,
    forceHideMenuBar: false,
    menuType: null,
    forceMobileHideMenuBar: false,
    publicLoggingIn: false,
    showSessionTimeoutModal: false,
    publicLoggedIn: isApiKeySessionValid(),
    isOffline: false,
    initialReferrer: null,
    headerMenuOpts: null,
  };
  return initialState;
};

export default function app(preState, action) {
  const state = preState ? preState : getInitialState();

  switch (action.type) {
    case FORCE_HIDE_MENU_BAR: {
      return Object.assign({}, state, {
        forceHideMenuBar: action.forceHideMenuBar,
        menuType: action.forceHideMenuBar ? action.menuType : null,
        forceMobileHideMenuBar: action.forceMobileHideMenuBar,
        headerMenuOpts: action.headerMenuOpts,
      });
    }
    case FATAL_ERROR:
      return Object.assign({}, state, {
        fatalError: action.fatalError,
        fullyInitialized: state.fullyInitialized,
      });
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        loggingIn: true,
        email: action.email,
        fullyInitialized: state.fullyInitialized,
        loggingInLevel: action.loggingInLevel,
      });
    case LOGIN_REQUEST_RESET_MIGRATE_USER:
      return Object.assign({}, state, {
        loggingIn: true,
        loggingInLevel: null,
      });
    case OCF_LOGIN_REQUEST:
      return Object.assign({}, state, {
        ocfLoggingIn: true,
        fullyInitialized: state.fullyInitialized,
        loggingInLevel: action.loggingInLevel,
      });
    case PUBLIC_LOGIN_REQUEST:
      return Object.assign({}, state, {
        fullyInitialized: state.fullyInitialized,
        loggingInLevel: action.loggingInLevel,
      });
    case PUBLIC_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        publicLoggingIn: true,
        fullyInitialized: state.fullyInitialized,
        loggingInLevel: null,
      });
    case OCF_LOGOUT:
      return Object.assign({}, state, {
        ocfLoggingIn: false,
        ocfLoggedIn: false,
        fullyInitialized: state.fullyInitialized,
        requireUserSuccess: state.requireUserSuccess,
        firstUserSuccessDetected: state.firstUserSuccessDetected,
        firstConfigSuccessDetected: state.firstConfigSuccessDetected,
        requireCreditProfileSuccess: state.requireCreditProfileSuccess,
        firstCreditProfileSuccessDetected:
          state.firstCreditProfileSuccessDetectedX,
      });
    case QUIT_AUTOMATIC_UPDATE_MODAL:
      return Object.assign({}, state, {
        showAutomaticUpdate: false,
      });
    case ACTIVATE_AUTOMATIC_UPDATE_MODAL:
      return Object.assign({}, state, {
        showAutomaticUpdate: true,
      });
    case QUIT_SESSION_TIMEOUT_MODAL:
      return Object.assign({}, state, {
        showSessionTimeoutModal: false,
      });
    case ACTIVATE_SESSION_TIMEOUT_MODAL:
      return Object.assign({}, state, {
        showSessionTimeoutModal: true,
      });
    case SET_INITIAL_REFERRER:
      return Object.assign({}, state, {
        initialReferrer: action.initialReferrer,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loggedIn: true,
        loggingIn: false,
        ocfLoggedIn: false,
        user: action.user,
        loginResponse: action.loginResponse,
        fullyInitialized: state.fullyInitialized,
        loggingInLevel: null,
      });
    case OCF_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        ocfLoggedIn: true,
        loggedIn: false,
        user: action.user,
        loginResponse: action.loginResponse,
        fullyInitialized: state.fullyInitialized,
        loggingInLevel: null,
      });
    case TOGGLE_OFFLINE_MODE:
      return Object.assign({}, state, {
        isOffline: !state.isOffline,
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        fullyInitialized: state.fullyInitialized,
        loggingIn: false,
        loggedIn: false,
        ocfLoggingIn: false,
        ocfLoggedIn: false,
        publicLoggingIn: false,
        publicLoggedIn: false,
        loggingInLevel: null,
      });
    case LOGOUT:
      return Object.assign({}, state, {
        loggedIn: false,
        fullyInitialized: state.fullyInitialized,
        requireUserSuccess: state.requireUserSuccess,
        firstUserSuccessDetected: state.firstUserSuccessDetected,
        firstConfigSuccessDetected: state.firstConfigSuccessDetected,
        requireCreditProfileSuccess: state.requireCreditProfileSuccess,
        firstCreditProfileSuccessDetected:
          state.firstCreditProfileSuccessDetected,
        user: null,
        loginResponse: null,
        ocfLoggingIn: false,
        ocfLoggedIn: false,
      });

    default:
      return state;
  }
}

import React from 'react';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import { ReactComponent as Warning } from 'style/_assets/icons/warning-red.svg';
import 'partial_OfferManager_modules_PremiumPaymentPendingAlert/PremiumPaymentPendingAlert.scss';

const PremiumPaymentPendingAlertMenuListView = props => {
  const {
    openFixPaymentModal,
    collapsed,
    isPremiumPaymentPendingUser,
    daysToUpdateBillingInfo,
    csTitle,
  } = props;

  if (collapsed || !isPremiumPaymentPendingUser) {
    return '';
  } else {
    return (
      <div className="PremiumPaymentPendingAlertMenuListView pad0 pos-rel">
        <FlexContainer
          column
          alignItemsCenter
          justifyCenter
          className="padL8 m-padL0"
        >
          <FlexContainer alignItemsStart justifyBetween className="margB10">
            <Warning aria-label="warning-icon" />
            <Label className="fontRed100 text-14 format85 lineHeight-1-21">
              Payment failed, you have {daysToUpdateBillingInfo} days to update
              payment to keep {csTitle}
            </Label>
          </FlexContainer>
          <FlexContainer column alignItemsCenter className="format100">
            <Button
              mrphType="primary"
              className="padTB2"
              rounded
              fullWidth
              onClick={openFixPaymentModal}
            >
              Update Payment Method
            </Button>
          </FlexContainer>
        </FlexContainer>
      </div>
    );
  }
};
PremiumPaymentPendingAlertMenuListView.defaultProps = {
  collapsed: false,
};
export default PremiumPaymentPendingAlertMenuListView;

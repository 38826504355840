import React from 'react';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import classnames from 'classnames';

const SpreadBox = props => {
  return (
    <FlexContainer
      className={classnames(props.className, props.widthClassName)}
      justifyBetween
      alignItemsCenter={props.alignItemsCenter && !props.alignItemsStart}
      alignItemsStart={props.alignItemsStart}
      row
      noWrap={props.noWrap}
      alignItemsStretch={props.alignItemsStretch}
      onClick={props.onClick}
    >
      {props.children}
    </FlexContainer>
  );
};

SpreadBox.defaultProps = {
  widthClassName: 'format100',
  m_column: false,
  alignItemsCenter: true,
  alignItemsStart: false,
  noWrap: false,
};
export default SpreadBox;

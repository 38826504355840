import React from 'react';
import { ReactComponent as ArrowBack } from 'components/premium/PremiumLandingCard/assets/arrow_back.svg';
import { ReactComponent as ArrowBackWhite } from 'components/premium/PremiumLandingCard/assets/arrow_back_white.svg';
import { CLICK } from 'externals/_tracking/mixpanel/types/Mixpanel.event.types';
import { useEventHandler } from 'hook/event.hook';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { OVERVIEW_URL } from 'types/mrph.routes.constants';

const ArrowView = props => {
  const { isPlusUser } = props ?? {};
  const ariaLabel = 'Go back to the homepage. stay Limited';
  const role = 'img';
  if (isPlusUser) {
    return <ArrowBackWhite aria-label={ariaLabel} role={role} />;
  } else {
    return <ArrowBack aria-label={ariaLabel} role={role} />;
  }
};

const StayLimited = ({ titleText, clickType, customOptions, isPlusUser }) => {
  const eventHandler = useEventHandler();
  const history = useHistory();
  const redirectOnClick = () => {
    let attr = {
      'Click Type': clickType,
    };
    if (customOptions) {
      attr = customOptions?.clickTypeAttr;
    }
    eventHandler(CLICK, attr);

    if (customOptions?.goBackUrl) {
      history.push(customOptions?.goBackUrl);
    } else {
      history.push(OVERVIEW_URL);
    }
  };

  const handleEnterKeyPress = event => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      redirectOnClick();
    }
  };
  const navbarClass = classnames('pointer', {
    stayLimitedPlus: isPlusUser,
    stayLimited: !isPlusUser,
  });

  return (
    <div
      onClick={redirectOnClick}
      className={navbarClass}
      tabIndex={'0'}
      onKeyDown={handleEnterKeyPress}
    >
      <nav className="padL10">
        <ArrowView isPlusUser={isPlusUser} />
        <span className="padL10 subTitle1 ">{titleText}</span>
      </nav>
    </div>
  );
};

export default StayLimited;

import { wrapService } from 'actions/service_wrapper.actions';
import {
  delete2FADevice,
  fetch2FADevices,
} from 'externals/_services/twofactor.service';
import {
  get2FAVerifiedSessionIdentifier,
  getCStrack,
} from 'helpers/localstorage';
import { get, isNully } from 'helpers/objectHelpers';
import { cleanPopupPaths } from 'helpers/path.helper';
import { getIgnoreIf2FAActive } from 'helpers/env';
import { poll } from 'helpers/polling.helper';
import {
  DEVICE_LOAD,
  DEVICE_LOAD_ERROR,
  DEVICE_LOAD_SUCCESS,
  DISABLE_2FA,
  REQUIRE_2FA,
  REQUEST_2FA_SECURITY_CODE,
} from 'types/twofactors.constants';

const a_fetch2FADevices = wrapService(fetch2FADevices, {
  name: 'fetch2FADevices',
});
const a_delete2FADevice = wrapService(delete2FADevice, {
  name: 'delete2FADevice',
});

export const requireTwoFactorAuthentication =
  (history, location, config) => (dispatch, getState) => {
    const {
      requireEnrollment, // Regardless if the user has 2FA or not, the user will be forced to enroll
      requirePromote, // Promote will change the session's role from USER_LIMITED to USER
      accountInfo,
      sessionIdentifier,
      isCashEnroll, //cash enroll has some special text
      finalRedirectedUrl,
    } = config;

    return new Promise((resolve, reject) => {
      const state = getState();
      const is2FAactive = requirePromote
        ? true
        : state.userInfo.userInfo.is2FAActive;

      const twoFactorProtection = get(
        state.config.config,
        ['appMangement', 'twoFactorProtectionOnHighRiskTransactions'],
        true,
      );
      const { urlOnCloseCashAccountModal } = state.cashEnrollmentState;

      const isSessionAlreadyVerified =
        getCStrack() === get2FAVerifiedSessionIdentifier() &&
        !isNully(getCStrack());

      if (isSessionAlreadyVerified && config.finalRedirectedUrl) {
        if (config?.applyPush) {
          history.push(config?.finalRedirectedUrl);
        } else {
          history.replace(config.finalRedirectedUrl);
        }
        resolve();
        return;
      }

      if (state.twofactor.isActionWaiting) {
        reject({ message: 'Two Factor Authentication already in progress' });
        return;
      }

      if (isSessionAlreadyVerified) {
        resolve();
        return;
      }

      if (!twoFactorProtection) {
        resolve();
        return;
      }

      if (
        !requirePromote &&
        ((!is2FAactive && !requireEnrollment) ||
          (is2FAactive && state.twofactor.session2FAVerified))
      ) {
        resolve();
        return;
      }

      if (requireEnrollment && !is2FAactive && !getIgnoreIf2FAActive()) {
        dispatch({
          type: REQUIRE_2FA,
          doEnroll: true,
          requirePromote,
          accountInfo,
          sessionIdentifier,
          isCashEnroll,
          finalRedirectedUrl,
          applyPush: config?.applyPush,
          showMfaProfileModal: true,
        });
      } else {
        dispatch({
          type: REQUEST_2FA_SECURITY_CODE,
          doEnroll: false,
          requirePromote,
          accountInfo,
          sessionIdentifier,
          finalRedirectedUrl,
          applyPush: config?.applyPush,
          showMfaProfileModal: false,
        });
      }
      let cachedPath = location?.pathname + location?.search; // get current path
      const newPath =
        urlOnCloseCashAccountModal ?? cleanPopupPaths(location?.pathname);

      history.replace(`${newPath}/2fa`);

      poll(() => {
        const newState = getState();
        return (
          newState.twofactor.session2FAVerified ||
          newState.twofactor.isActionWaiting === false
        );
      }, 3000000000)
        .then(_ => {
          const newState = getState();
          if (newState.twofactor.session2FAVerified) {
            if (config.finalRedirectedUrl) {
              resolve();
            } else if (config.resolveWithoutPopup) {
              const cleanedPath = cleanPopupPaths(cachedPath);
              history.replace(cleanedPath);
              resolve();
            } else {
              history.replace(cachedPath);
              resolve();
            }
          } else {
            reject({ message: 'Two Factor Authentication not completed' });
          }
        })
        .catch(reject);
    });
  };

export const disableTwoFactorAuthentication =
  (history, location) => (dispatch, getState) => {
    let currentPath = location.pathname; // get current path
    const newPath = cleanPopupPaths(currentPath);
    history.replace(`${newPath}/2fa`);

    return new Promise((resolve, reject) => {
      dispatch({ type: DISABLE_2FA });
    });
  };

const startTrustedDeviceLoad = () => ({ type: DEVICE_LOAD });
const completeTrustedDeviceLoad = payload => ({
  type: DEVICE_LOAD_SUCCESS,
  payload,
});
const failTrustedDeviceLoad = error => ({ type: DEVICE_LOAD_ERROR, error });

export const loadTrustedDevices = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(startTrustedDeviceLoad());

    dispatch(a_fetch2FADevices())
      .then(payload => {
        dispatch(completeTrustedDeviceLoad(payload));
        resolve(payload);
      })
      .catch(err => {
        dispatch(failTrustedDeviceLoad(err));
        reject(err);
      });
  });
};

export const removeTrustedDevices = idList => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(a_delete2FADevice(idList.join(',')))
      .then(_ => {
        return dispatch(loadTrustedDevices());
      })
      .then(_ => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};

import React, { useState, useEffect, useCallback } from 'react';
import './StickyInfoBar.scss';
import { Row } from 'components/Containers/Row/Row';
import Accordion from 'components/Accordion/index';
import Label from 'components/form/Label/Label';

const StickyInfoBar = ({ scrollStates }) => {
  return (
    <Row className="sticky-footer-container">
      <Accordion
        title={
          <Label type="H4" typeHeader="h2" className="margB0">
            What You Need to Know:
          </Label>
        }
        titleClassName="justify-end flex-row-reverse gap-12"
        openByDefault={true}
        scrollState={scrollStates}
        body={
          <Label className="caption">
            The credit scores provided are based on the{' '}
            <span className="bold">VantageScore® 3.0 model</span>. Lenders use a
            variety of credit scores and are likely to use a credit score
            different from <span className="bold">VantageScore® 3.0</span> to
            assess your creditworthiness.
          </Label>
        }
      />
    </Row>
  );
};

export default StickyInfoBar;

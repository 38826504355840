export const TIME_LINES = [
  {
    timeLineId: 'TODAY',
    label: 'Today',
    classNameRow: 'bgWhite secondary_text_light',
    className: 'bgGray1',
    defaultMessage:
      'No events to take action today. Visit regularly to be on top of your credit.',
  },
  {
    timeLineId: 'PAST_TWO_PLUS_WEEKS',
    label: 'Past 2 weeks +',
    className: 'bgGrayN060',
  },
  {
    timeLineId: 'PAST_TWO_WEEKS',
    label: 'Past 2 weeks',
    className: 'bgGrayN060',
  },
  { timeLineId: 'LAST_WEEK', label: 'Last week', className: 'bgGrayN060' },
  { timeLineId: 'TAKE_ACTION', label: 'Take action', className: 'bgGray1' },
  {
    timeLineId: 'THIS_WEEK',
    label: 'This week',
    classNameRow: 'bgWhite',
    className: 'bgGray1',
  },
  {
    timeLineId: 'NEXT_TWO_WEEKS',
    label: 'In 2 weeks',
    classNameRow: 'bgWhite',
    className: 'bgGray1',
  },
  {
    timeLineId: 'NEXT_TWO_PLUS_WEEKS',
    label: 'In 2 weeks +',
    classNameRow: 'bgWhite',
    className: 'bgGray1',
  },
];

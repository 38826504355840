import React from 'react';
import { getImageBasePath } from 'helpers/env';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button/index';
import { ErrorTemplate } from './ErrorTemplate';
import { useConfig } from 'hook/config.hook';
import OText from 'components/CText/OText';

export const CreditProfileError = ({
  errorType,
  showButtonToExploreOffers,
  ...props
}) => {
  const { config } = useConfig();
  return (
    <ErrorTemplate
      errorType={errorType}
      title="Credit file not found"
      imgSrc={config?.signUpError?.creditProfileErr?.imgSrc}
    >
      <Label className="padTB8 margB16">
        <OText text={config?.signUpError?.description?.mainContent} />
      </Label>
      <Label className="padTB8 margB16">
        <OText text={config?.signUpError?.description?.subContent} />
      </Label>
      {showButtonToExploreOffers && (
        <Button mrphType="primary" onClick={props.onButtonClick}>
          Explore Offers
        </Button>
      )}
    </ErrorTemplate>
  );
};

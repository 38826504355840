export const RESET_VERIFY_ACCOUNT = 'RESET_VERIFY_ACCOUNT';
export const SET_VERIFY_ACCOUNT_DATA = 'SET_VERIFY_ACCOUNT_DATA';
export const SET_VERIFY_ACCOUNT_STEP = 'SET_VERIFY_ACCOUNT_STEP';
export const SET_VERIFY_ACCOUNT_EMAIL_CONFIRM =
  'SET_VERIFY_ACCOUNT_EMAIL_CONFIRM';
export const SET_VERIFY_ACCOUNT_PHONE_NUMBER_CONFIRM =
  'SET_VERIFY_ACCOUNT_PHONE_NUMBER_CONFIRM';
export const SET_EMAIL_OTP_REQUIREMENT = 'SET_EMAIL_OTP_REQUIREMENT';
export const SET_PHONE_OTP_REQUIREMENT = 'SET_PHONE_OTP_REQUIREMENT';
export const SET_VERIFY_ACCOUNT_OTP_ATTEMPTS_EXCEEDED =
  'SET_VERIFY_ACCOUNT_OTP_ATTEMPTS_EXCEEDED';

export const OTP_MAX_RETRY_CODES = ['TFA_1025', 'CPO_1010'];

import {
  FINISH_GOAL_ACTION_REQUEST,
  FINISH_GOALS_LOAD,
  GOALS_ERROR,
  START_GOALS_LOAD,
} from '../types/goals.constants';
import { wrapService } from './service_wrapper.actions';
import {
  fetchGoalAction,
  fetchGoals,
} from '../externals/_services/goals.service';
import { buildPayloadDispatcher } from '../types/payload.constants';
import { createLogger } from '../helpers/logger';
import { getOffersRequestParameters } from 'helpers/offer.helpers';
import { updateFiltersGoalsGet } from 'helpers/goals.helpers';

const logger = createLogger({
  name: 'goals.actions',
  categories: ['goals'],
});

const startGoalsLoading = () => ({ type: START_GOALS_LOAD });

const a_fetchGoals = wrapService(fetchGoals, {
  name: 'goals_fetchUserGoals',
});

const a_fetchGoalAction = wrapService(fetchGoalAction, {
  name: 'goals_fetchUserGoalAction',
});

export const updateGoalsDataPayload = buildPayloadDispatcher(
  'goals',
  FINISH_GOALS_LOAD,
);

export const updateGoalActionPayload = buildPayloadDispatcher(
  'goalAction',
  FINISH_GOAL_ACTION_REQUEST,
);

const onErrorFetchGoals = buildPayloadDispatcher('goals_error', GOALS_ERROR);

export const loadGoals = props => (dispatch, getState) => {
  const paramsFromFlag = getOffersRequestParameters();
  const filters = {
    cciPctThreshold: paramsFromFlag?.showPercent,
    guaranteeBadge: paramsFromFlag?.guaranteeBadge,
    approvalOddsBadgeOverride: paramsFromFlag?.approvalOddsBadgeOverride,
    cciVersion: paramsFromFlag?.cciVersion,
    rankingType: paramsFromFlag?.rankingType,
  };

  dispatch(startGoalsLoading());
  return new Promise((resolve, reject) => {
    dispatch(a_fetchGoals(filters))
      .then(res => {
        dispatch(updateGoalsDataPayload(res.goals, false, null));
        resolve(res);
      })
      .catch(err => {
        dispatch(onErrorFetchGoals(null, false, err));
        logger.reportAPIError(err);
        reject(err);
      });
  });
};

export const loadGoalAction = actionId => dispatch => {
  const paramsFromFlag = getOffersRequestParameters();
  const filters = {
    cciPctThreshold: paramsFromFlag?.showPercent,
    guaranteeBadge: paramsFromFlag?.guaranteeBadge,
    cciVersion: paramsFromFlag?.cciVersion,
    rankingType: paramsFromFlag?.rankingType,
  };
  dispatch(startGoalsLoading());
  return new Promise((resolve, reject) => {
    dispatch(a_fetchGoalAction(actionId, filters))
      .then(res => {
        dispatch(updateGoalActionPayload(res, false, null));
        resolve(res);
      })
      .catch(err => {
        dispatch(onErrorFetchGoals(null, false, err));
        logger.reportAPIError(err);
        reject(err);
      });
  });
};

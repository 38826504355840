export const cmAlertTypes = {
  DELINQUENT_ACCOUNT: 'Delinquent Account',
  IMPROVED_ACCOUNT: 'Improved Account',
  BANKRUPTCY: 'Bankruptcy',
  PUBLIC_RECORD: 'Public Record',
  DECREASE_IN_COLLECTION_BALANCES: 'Decrease in Collection Balances',
  INCREASE_IN_COLLECTION_BALANCES: 'Increase in Collection Balances',
  ACCOUNT_CHARGE_OFF: 'Account Charge-off',
  FORCLOSURE: 'Foreclosure',
  COLLECTIONS_EXCLUDING_MEDICAL: 'Collections Excluding Medical',
  PAID_COLLECTION: 'Paid Collection',
  CARD_OVER_LIMIT: 'Card Over Limit',
  PAID_TRADELINE: 'Paid Tradeline',
  RETAIL_CARD_OVERLIMIT: 'Retail Card Over Limit',
  NEW_AUTHORIZED_USER: 'New Authorized User',
  NEW_ACCOUNT: 'New Account',
  CLOSED_ACCOUNT: 'Closed Account',
  MISSING_CARD: 'Missing Card',
  NEW_AUTO_LEASE: 'New Auto Lease',
  NEW_INQUIRY: 'New Inquiry',
  ENHANCED_NEW_INQUIRY: 'Enhanced New Inquiry',
  NEW_ADDRESS: 'New Address',
  NEW_EMPLOYER: 'New Employer',
  FRAUD_ALERT: 'Fraud Alert',
  CREDIT_SCORE_INCREASE: 'Credit Score Increase',
  CREDIT_SCORE_DECREASE: 'Credit Score Decrease',
  REPORT_FROZEN: `Report Frozen`,
  REPORT_UNFROZEN: `Report Unfrozen`,
  REPORT_LOCKED: `Report Locked`,
  REPORT_UNLOCKED: `Report Unlocked`,
  ATTEMPTED_INQUIRY_LOCK: 'Report pull attempted while locked',
  ATTEMPTED_INQUIRY_FREEZE: 'Report pull attempted while frozen',
};

export const cmAlertNamesMapping = {
  [cmAlertTypes.DECREASE_IN_COLLECTION_BALANCES]:
    'Collections Balance Decrease',
  [cmAlertTypes.MISSING_CARD]: 'Lost or Stolen Card',
  [cmAlertTypes.PAID_COLLECTION]: 'Collections Account Paid Off',
  [cmAlertTypes.PAID_TRADELINE]: 'Account Paid',
  [cmAlertTypes.IMPROVED_ACCOUNT]: 'Account Improved',
  [cmAlertTypes.CLOSED_ACCOUNT]: 'Account Closed',
  [cmAlertTypes.RETAIL_CARD_OVERLIMIT]: cmAlertTypes.CARD_OVER_LIMIT,
  [cmAlertTypes.COLLECTIONS_EXCLUDING_MEDICAL]: 'New Collections Account',
  [cmAlertTypes.INCREASE_IN_COLLECTION_BALANCES]:
    'Collections Balance Increase',
  [cmAlertTypes.PUBLIC_RECORD]: 'New Public Record',
  [cmAlertTypes.ENHANCED_NEW_INQUIRY]: 'New Inquiry',
  [cmAlertTypes.ACCOUNT_CHARGE_OFF]: 'Account Charged Off',
  [cmAlertTypes.CARD_OVER_LIMIT]: 'Credit Over Limit',
  [cmAlertTypes.ATTEMPTED_INQUIRY_LOCK]: 'Attempted Inquiry (lock)',
  [cmAlertTypes.ATTEMPTED_INQUIRY_FREEZE]: 'Attempted Inquiry (freeze)',
};

export const inquiryAutoLoanIndustryType = 'Auto Financing, Auto Dealers, Used';
export const inquiryMortgageIndustryType = 'Credit Bureaus/Mortgage Processing';

export const accountDetailsCategory = [
  cmAlertTypes?.BANKRUPTCY,
  cmAlertTypes?.PUBLIC_RECORD,
  cmAlertTypes?.INCREASE_IN_COLLECTION_BALANCES,
];

import * as Yup from 'yup';
import { accountVerificationOtpError } from 'actions/accountVerification.actions';
import {
  validatePasswordCase1,
  validatePasswordCase2,
  validatePasswordCase3,
  validatePasswordCase4,
  validatePhoneUS,
} from 'helpers/signup.helpers';
import { isPremium } from 'helpers/userHelper';
import {
  MIGRATION_CONFIRM_FREE,
  MIGRATION_CONFIRM_PREMIUM,
} from 'externals/_tracking/types/eventTypes.constants';

export const WELCOME_VERIFICATION = 'WELCOME_VERIFICATION';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const CONFIRM_PHONE = 'CONFIRM_PHONE';
export const EDIT_PHONE = 'EDIT_PHONE';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const VERIFY_OTP_EMAIL = 'VERIFY_OTP_EMAIL';
export const VERIFY_OTP_PHONE = 'VERIFY_OTP_PHONE';
export const PASSWORD_REQUIRED_CRITERIA =
  'Password must meet the required criteria.';

export const CREATE_PASSWORD_INITIAL_VALUES = {
  password: '',
  confirmPassword: '',
};

export const CREATE_PASSWORD_SCHEMA = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .test('min-length', PASSWORD_REQUIRED_CRITERIA, value =>
      validatePasswordCase1(value),
    )
    .test('uppercase', PASSWORD_REQUIRED_CRITERIA, value =>
      validatePasswordCase2(value),
    )
    .test('lowercase', PASSWORD_REQUIRED_CRITERIA, value =>
      validatePasswordCase3(value),
    )
    .test('number', PASSWORD_REQUIRED_CRITERIA, value =>
      validatePasswordCase4(value),
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const EDIT_EMAIL_SCHEMA = Yup.object({
  email: Yup.string()
    .lowercase()
    .email('Please enter a valid email address')
    .required('Email is required'),
  confirmEmail: Yup.string()
    .lowercase()
    .oneOf([Yup.ref('email'), null], 'Email does not match')
    .required('Confirm your email'),
});

export const EDIT_PHONE_SCHEMA = Yup.object({
  contactPhoneNumber: Yup.string()
    .test('validate-phone', 'Please enter a valid phone number', value => {
      const errorMessage = validatePhoneUS(value);
      return errorMessage === false;
    })
    .required('Phone number is required'),
});

export const accountVerificationPageNames = {
  [WELCOME_VERIFICATION]: 'New Experience First Login',
  [CREATE_PASSWORD]: 'Create Password First Login',
  [CONFIRM_EMAIL]: 'Confirm Email First Login',
  [CONFIRM_PHONE]: 'Confirm Phone First Login',
  [EDIT_EMAIL]: 'Edit Email First Login',
  [EDIT_PHONE]: 'Edit Phone First Login',
  [VERIFY_OTP_EMAIL]: 'Confirm Email OTP First Login',
  [VERIFY_OTP_PHONE]: 'Confirm Phone OTP First Login',
};

export const handleOtpErrorForMigratedUser = dispatch => {
  dispatch(accountVerificationOtpError());
};

export const getMigrationEvent = userInfo => {
  return isPremium(userInfo)
    ? MIGRATION_CONFIRM_PREMIUM
    : MIGRATION_CONFIRM_FREE;
};

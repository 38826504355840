import {
  PERSONAL_LOAN_PREQUAL_SELECTED,
  AUTO_LOAN_FORM_COMPLETED,
} from 'types/product.constants';
import { fetchOffers } from 'externals/_services/offers.service';
import { fetchDebtReliefProducts } from 'externals/_services/product.service';
import { updateSessionTimeoutInLocal } from 'helpers/session';
import { getOffersRequestParameters } from 'helpers/offer.helpers';
import { wrapService } from 'actions/service_wrapper.actions';
import {
  CREDIT_CARDS_CONTAINER_PAYLOAD,
  DEBT_RELIEF_PAYLOAD,
  HOME_PAGE_CONTAINER_PAYLOAD,
} from 'types/payload.constants';

const a_fetchCreditCardContainer = wrapService(fetchOffers, {
  name: 'fetchCreditCardContainer',
});
const a_fetchHomeContainer = wrapService(fetchOffers, {
  name: 'fetchHomeContainer',
});
const a_fetchCreditCardCategories = wrapService(fetchOffers, {
  name: 'fetchCreditCardCategories',
});
const a_fetchDebtReliefProducts = wrapService(fetchDebtReliefProducts, {
  name: 'fetchDebtReliefProducts',
});

export const selectPersonalLoanForPrequalification = prequalPersonalLoan => ({
  type: PERSONAL_LOAN_PREQUAL_SELECTED,
  prequalPersonalLoan,
});

export const completeAutoLoanFormForOffers = autoLoanFormConfig => ({
  type: AUTO_LOAN_FORM_COMPLETED,
  autoLoanFormConfig,
});

const creditCardContainerPayload = (
  creditCardContainer,
  creditCardContainerLoading,
  creditCardContainerFailure,
) => ({
  type: CREDIT_CARDS_CONTAINER_PAYLOAD,
  creditCardContainer,
  creditCardContainerLoading,
  creditCardContainerFailure,
});

const startLoadCreditCardContainer = () =>
  creditCardContainerPayload(null, true, null);
const succeedCreditCardContainerLoad = payload =>
  creditCardContainerPayload(payload, false, null);
const failCreditCardContainerLoad = error =>
  creditCardContainerPayload(null, false, error);

export const loadCreditCardContainer =
  (creditInfoArg, consolidateSimulation, doNotClear) =>
  (dispatch, getState) => {
    if (!doNotClear) {
      dispatch(startLoadCreditCardContainer());
    }
    const paramsFromFlag = getOffersRequestParameters();

    let queryParams = {
      container: 'CREDIT_CARDS',
      clientType: 'WEB',
      rankingType: paramsFromFlag?.rankingType,
      scoreAction: consolidateSimulation ? 'true' : undefined,
      cciPctThreshold: paramsFromFlag?.showPercent,
      guaranteeBadge: paramsFromFlag?.guaranteeBadge,
      approvalOddsBadgeOverride: paramsFromFlag?.approvalOddsBadgeOverride,
      cciVersion: paramsFromFlag?.cciVersion,
    };

    return new Promise((resolve, reject) => {
      dispatch(a_fetchCreditCardContainer(queryParams))
        .then(res => {
          dispatch(succeedCreditCardContainerLoad(res));
          resolve(res);
        })
        .catch(err => {
          dispatch(failCreditCardContainerLoad(err));
          reject(err);
        });
    });
  };

const homePageContainerPayload = (
  homePageContainer,
  homePageContainerLoading,
  homePageContainerFailure,
) => ({
  type: HOME_PAGE_CONTAINER_PAYLOAD,
  homePageContainer,
  homePageContainerLoading,
  homePageContainerFailure,
});
const startLoadHomePageContainer = () =>
  homePageContainerPayload(null, true, null);
const succeedHomePageContainerLoad = payload =>
  homePageContainerPayload(payload, false, null);
const failHomePageContainerLoad = error =>
  homePageContainerPayload(null, false, error);

export const loadHomePageContainer = creditInfoArg => (dispatch, getState) => {
  const paramsFromFlag = getOffersRequestParameters();

  dispatch(startLoadHomePageContainer());

  let queryParams = {
    container: 'HOME_PAGE',
    clientType: 'WEB',
    excludeApprovedDeclinedOffers: true,
    rankingType: paramsFromFlag?.rankingType,
    cciPctThreshold: paramsFromFlag?.showPercent,
    guaranteeBadge: paramsFromFlag?.guaranteeBadge,
    approvalOddsBadgeOverride: paramsFromFlag?.approvalOddsBadgeOverride,
    cciVersion: paramsFromFlag?.cciVersion,
  };

  return new Promise((resolve, reject) => {
    dispatch(a_fetchHomeContainer(queryParams))
      .then(res => {
        updateSessionTimeoutInLocal();
        dispatch(succeedHomePageContainerLoad(res));
        resolve(res);
      })
      .catch(err => {
        dispatch(failHomePageContainerLoad(err));
        //logger.addFatal(err);
        reject(err);
      });
  });
};

const creditCardCategoriesContainerPayload = (
  creditCardCategoriesContainer,
  creditCardCategoriesContainerLoading,
  creditCardCategoriesContainerFailure,
) => ({
  type: CREDIT_CARDS_CONTAINER_PAYLOAD,
  creditCardCategoriesContainer,
  creditCardCategoriesContainerLoading,
  creditCardCategoriesContainerFailure,
});
const startLoadCreditCardCategoriesContainer = () =>
  creditCardCategoriesContainerPayload(null, true, null);
const succeedCreditCardCategoriesContainerLoad = payload =>
  creditCardCategoriesContainerPayload(payload, false, null);
const failCreditCardCategoriesContainerLoad = error =>
  creditCardCategoriesContainerPayload(null, false, error);

export const loadCreditCardCategoriesContainer =
  (creditInfoArg, doNotClear) => (dispatch, getState) => {
    if (!doNotClear) {
      dispatch(startLoadCreditCardCategoriesContainer());
    }

    const paramsFromFlag = getOffersRequestParameters();

    return new Promise((resolve, reject) => {
      let queryParams = {
        container: 'CREDIT_CARD_CATEGORIES',
        clientType: 'WEB',
        rankingType: paramsFromFlag?.rankingType,
        cciPctThreshold: paramsFromFlag?.showPercent,
        guaranteeBadge: paramsFromFlag?.guaranteeBadge,
        cciVersion: paramsFromFlag?.cciVersion,
        approvalOddsBadgeOverride: paramsFromFlag?.approvalOddsBadgeOverride,
      };

      dispatch(a_fetchCreditCardCategories(queryParams))
        .then(res => {
          updateSessionTimeoutInLocal();
          dispatch(succeedCreditCardCategoriesContainerLoad(res));
          resolve(res);
        })
        .catch(err => {
          dispatch(failCreditCardCategoriesContainerLoad(err));
          //logger.addFatal(err);
          reject(err);
        });
    });
  };

const onDebtReliefPayloadChange = (
  debtReliefProducts,
  debtReliefProductsLoading,
  debtReliefProductsFailure,
) => {
  return {
    type: DEBT_RELIEF_PAYLOAD,
    debtReliefProducts,
    debtReliefProductsLoading,
    debtReliefProductsFailure,
  };
};
const startLoadDebtReliefProducts = () =>
  onDebtReliefPayloadChange(null, true, null);
const successDebtReliefProductsLoad = payload =>
  onDebtReliefPayloadChange(payload, false, null);
const failDebtReliefProductsLoad = error =>
  onDebtReliefPayloadChange(null, false, error);

export const loadDebtReliefProducts = () => (dispatch, getState) => {
  dispatch(startLoadDebtReliefProducts());

  return new Promise((resolve, reject) => {
    dispatch(a_fetchDebtReliefProducts())
      .then(res => {
        updateSessionTimeoutInLocal();
        dispatch(successDebtReliefProductsLoad(res));
        resolve(res);
      })
      .catch(err => {
        dispatch(failDebtReliefProductsLoad(err));
        reject(err);
      });
  });
};

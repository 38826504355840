import { areCookiesEnabled } from 'externals/_tracking/dom/navigator';
import { getPageFormFactor } from 'externals/_tracking/dom/document';
import { isCardStatusFrozen } from 'pages/Cash/helpers/cashHelpers';
import {
  isOcfSessionValid,
  isSessionValid,
} from 'helpers/sessionLocalStorageReader.helper';
import { isCb20Enrolled } from './sesameCash.helpers';
import { isFromNativeApp } from './session';
import { PAGE_FORM_FACTOR_TITLE } from 'helpers/env';
export const getSiteAttributeValue = () => {
  return areCookiesEnabled() && (isSessionValid() || isOcfSessionValid())
    ? 'Secure'
    : 'Public';
};

export function getMrphPageFormFactor() {
  let formFactor = getPageFormFactor();
  if (formFactor === 'Mobile') {
    formFactor = 'Mobile Web';
  }
  return PAGE_FORM_FACTOR_TITLE + ' ' + formFactor;
}

export const getCashTrackingProperties = (userInfo, sesameCash) => {
  let cashTrkProperties = {
    'Cash Enroll Status': userInfo?.banking?.enrollStatus ?? 'Unknown',
    'Card Funded': sesameCash?.cashAccount?.receivedFirstFund ? true : false,
    'Card PIN Set': sesameCash?.cashAccount?.changedFirstPin ? true : false,
    'CB 2.0 Enrolled': isCb20Enrolled(sesameCash?.cashAccount, userInfo)
      ? 'TRUE'
      : 'FALSE',
  };

  if (sesameCash.cashAccount) {
    cashTrkProperties['Card Frozen'] = isCardStatusFrozen(
      sesameCash.cashAccount,
    );
  }
  return cashTrkProperties;
};

export const getInViewBrowserNativeAppTrackingProperties = () => {
  // Implementing this function to add tracking events when user is using native mobile app and viewing the page in a browser
  //  I will be removed adding an improvement to handle
  const isNativeApp = isFromNativeApp();
  const trackingAttributes = {};
  if (isNativeApp) {
    trackingAttributes['From Native App'] = true;
  }
  return trackingAttributes;
};

import { BANKING_VERTICAL } from 'types/mrph.vertical.constants';

const t = {
  homeLoans: 'Home Loans',
  InformationAndTools: 'Information & tools',
  newHome: 'New Home',
  homeRefinance: 'Home Refinance',
  aboutMortgage: 'About Mortgages',
  aboutRefinance: 'About Refinance',
  homeEquity: 'Home Equity',
  calculateMonthlyPayment: 'Calculate Monthly Payments',
  whyDoWeIncludeAPR: 'Why do we include APR?',
  whatToKnowBeforeGettingMortgage: 'What to know before getting a mortgage?',
  whatToKnowAboutRefinance: 'What to know about refinance?',
  someTipsForFirstNewHome:
    'APR (Annual Percentage Rate) can show you the true cost of a loan since it includes the interest rate plus any fees you might pay.',
  MonthlyPaymentCalculator: 'Monthly Payment Calculator',
  MonthlyPaymentCalculatorTileText: 'Monthly payment calculator',
  crediSesameHomeLoanMarketplace: 'Home Loan Marketplace',
  getASenseOfHowMuch:
    'Get a sense of how much home you can afford and what your potential down payment could be',
  howMuchYouCanAffort:
    'Understanding how much home you can afford and what your potential down payment could be really gives you an idea if you are in a good spot to move forward.',
  findARealEstateAgent: 'Find a real estate agent in your area',
  realEstateAgentsWillHelp:
    'Real estate agents will help you look for your dream home. Having an experienced real estate agent on your side makes the home searching process easy. They can help explain the home buying process, potentially negotiate a better contract sale and give you better insight into the area you would like to buy your first home than if you went at it alone.',
  gatherDocuments: 'Gather documents',
  documentsLenderWillAsk:
    'The basic documents your mortgage lender will ask you for are typically current paystubs covering a 30 day period, last 2 years of your W2s and tax returns, most recent 2 months bank or investment statements,  and be sure to document any additional income that you may have such as rental income, social security or alimony or child support. Your mortgage lender may ask for additional documentation further down your application process.',
  chooseRighLender: 'Choose the right lender for you',
  howToFindaLender:
    'No one lender will work for all situations. You’ll want to find a lender that you feel comfortable working with and who also knows your area. We recommend speaking with at least 3 lenders prior to deciding on the right lender for you. We make it easy by asking you a few simple questions to filter through our mortgage lending partners to find the best one personalized to your needs.',
  howToFindaLenderRefinance:
    'No one lender will work for all situations. You’re going to want to find a lender for you that can work in your state and that offers competitive loan products and interest rates. We make it easy by asking you a few simple questions to filter through our mortgage lending partners to find the best one personalized to your needs.',
  whatYouNeedToRefinance: 'What you need to know about refinance?',
  whenNeedFinancialFlexibility: 'Financial flexibility when you need it',
  whyRefinanceYourMortgage: 'Why refinance your mortgage?',
  reasonsForRefinancing:
    'Common reasons include: pay off your mortgage faster, lower your monthly payment or your interest rate, or pull out some cash.',
  reduceMonthlyPayment:
    'Reduce your monthly mortgage payment by locking in a lower interest rate',
  requestQuotesFromThreeLanders:
    'Refinancing toward a lower interest rate depends primarily on market interest rates but there can be a wide variability from lender to lender so be sure to request quotes from at least 3 lenders.',
  cashOutRefinance: 'Pull cash out of your home with a cash-out refinance',
  cashOutRefinanceInfo:
    'A cash-out refinance converts the equity into a larger mortgage loan than your current mortgage. Most borrowers who decide to cash-out refinance use the cash to do major home remodeling projects, consolidate their debt, pay for college tuition(s) or even a down payment for a second home.',

  howToStartRefinancing: 'How to start refinancing your home mortgage:',
  calculateHowMuchIcanAffort: 'Calculate how much you can afford',
  calculateHowMuchIcanAffortInfo:
    'Calculate how much monthly mortgage payment you can afford if you are deciding to cash-out refinance at a larger loan amount or if you are reducing the term of your loan to pay your mortgage off faster. Knowing this gives you a better idea of what your mortgage loan amount would be and if doing a cash-out or term refinance is realistic for you as a borrower.',
  thisMortgageCalculatorWillHelp:
    'This mortgage calculator will help you estimate your monthly mortgage payment.',
  homePrice: 'Home Price',
  downPayment: 'Down Payment',
  interestRate: 'Interest Rate',
  terms: 'Terms',
  calculate: 'Calculate',
  estimatedMonthlyPayment: 'Estimated monthly payment',
  yourCustomizedRates: 'Your Customized Rates',
  homeLoanOfferInterestTooltip:
    'Your interest rate is the percentage you pay to borrow money from a lender for a specific period of time. Rates are provided by hsh.com.',
  homeLoanOfferAPRTooltip:
    'Your APR (Annual Percentage Rate) is your interest rate plus other fees, such as mortgage insurance, broker fees, and closing costs. Rates are provided by hsh.com.',
  cash: {
    securityCheck: {
      title: 'Your Security is Our Priority',
      modalName: 'Sesame Cash Plaid',
    },
    cashDeposit: {
      title: 'Cash Deposit',
      modalName: 'Cash Transfers',
    },
    enrolledWelcomeView: {
      title: 'Welcome to Sesame Cash',
      descriptionVar1:
        'Add money to your account to complete enrollment and access all the benefits Sesame Cash has to offer like no monthly fees or minimum balance, cash rewards for improving your credit score, and up to 15% cash back on everyday purchases.',
      descriptionVar2:
        'Add just $100 to your account each month to unlock features like cash rewards for improving your credit score (up to $100!), daily credit score refreshes, and more.',
    },
    optionsView: {
      modalName: 'Move Money',
      accountFundedTitle: 'Move Money',
      accountFundedDescription:
        "Choose how you'd like to add money to your account.",
      accountNotFundedModalName: 'Where’s My Card?',
      accountNotFundedTitle: 'Make a Deposit',
      accountNotFundedDescription: `Make a deposit to unlock features like cash back offers and device protection!`,
    },
    howToFundVideoView: {
      title: 'How to Add Money',
      description:
        'Watch this video for a quick overview of how to easily add money to your Sesame Cash account.',
      modalName: 'Add Money Video',
    },
    bankTransfer: {
      modalName: {
        1: 'Sesame Cash Linked Bank Accounts',
        2: 'Sesame Cash Plaid Transfer Receiving Account',
        3: 'Sesame Cash Transfer Funds',
        4: 'Sesame Cash *transferType* Transfer Success',
        manageExternalAccounts: 'Manage External Accounts',
        transferOptionsName: 'Load via ACH',
      },
      title: 'Transfer from Other Banks',
      description: {
        1: 'Select a bank account to transfer money from. When transferring money, please ensure the account you are transferring from has sufficient money.',
        2: 'Select a bank account to transfer money to.',
      },
    },
    oauthLanding: {
      title: '',
    },
    directDeposit: {
      modalName: 'Direct Deposit',
      title: 'Direct Deposit',
      description:
        "Get paid up to 2 days early when you add your paycheck to Sesame Cash. Give your employer your routing and account numbers below--it's that simple!",
      footer:
        'Payroll deposit and earlier availability of funds is subject to payer\'s support of the feature and timing of payer\'s funding.<br/><br/>You may see a message referencing our partner <span class="bold">Community Federal Savings Bank (CFSB)</span> when you transfer funds to Sesame Cash.',
    },
    instantTransfer: {
      modalName: 'Instant Transfers',
      title: 'Instant Transfers',
      description:
        'Make instant transfers to your Sesame Cash debit card. An instant transfer works with digital wallets like Paypal or companies like Uber and Lyft.',
      footer:
        'Receiving Instant Transfers are free, but the digital wallet may charge you a fee. Transfer speeds vary by digital wallet but typically take less than 30 minutes.',
    },
    checkDeposit: {
      modalName: 'Deposit a Check',
      title: 'Deposit a Check',
      description:
        'Deposit checks instantly into your Sesame Cash account with Ingo.',
      footer:
        "We don't charge a fee for check deposit, but the Ingo Money app might.",
    },
  },
  checkout: {
    disclaimers: {
      freeTrial:
        'By completing enrollment, you agree to begin your 7-day Platinum premium trial membership. You can cancel your trial at anytime. If you do not cancel your trial membership at least 24 hours before the end of the trial period, your credit card will be charged the standard Platinum premium membership rate of $19.95/mo and charged on a recurring monthly basis. Your subscription will continue to renew unless cancelled at least 24 hours before the next renewal date. If you would like to cancel a paid premium membership, please review our ',
      premium:
        'By completing enrollment, you agree that your card will be charged on the same date as your original premium enrollment date and charged on a recurring monthly basis. For Advanced premium, you will be charged the standard rate of $9.95/mo, for Pro premium $15.95/mo, and for Platinum premium $19.95/mo. Your subscription will automatically renew unless cancelled at least 24 hours before the next renewal date. If you would like to cancel a paid premium membership, please review our ',
      turboOneWeekTrial:
        'By completing enrollment, you agree to begin your 7-day Turbo premium trial membership. Billing begins at the end of your free trial and will automatically renew every month at the standard rate of $9.57. You may cancel at any time in your profile. ',
      turboOneWeekTrialFullPrice:
        'By completing enrollment, you agree to begin your 7-day Turbo premium trial membership. Billing begins at the end of your free trial and will automatically renew every month at the standard rate of $15.99. You may cancel at any time in your profile. ',
      platnium:
        'By completing enrollment, you agree to begin your Turbo premium membership. Billing automatically renews every month at the standard rate of $15.95. You may cancel at any time in your profile. ',
      croa: 'You can cancel your trial anytime. $8.99 setup fee will be refunded if you cancel within 5 days of signup. Your credit card will be charged the Turbo premium membership rate of $9.57/mo at the end of the first month, and charge on a recurring monthly basis. If you would like to cancel, please review our ',
      croaPostTermsOfUse:
        ' for the appropriate steps depending on the type of account(s) you have with Credit Sesame.',
      noDiscountVar6:
        'By completing enrollment, you agree to begin your Turbo premium membership at the discounted rate of $5.99 for the first month. Your membership will automatically renew every month at the standard rate of $15.99. You may cancel at any time in your profile.',
      noDiscountVar7AnnualPrice:
        'By completing enrollment, you agree to begin your annual Turbo premium membership at the discounted rate of $59.99. Your membership will automatically renew every year. You may cancel at any time in your profile. ',
      noDiscountVar8SemiAnnualPrice:
        'By completing enrollment, you agree to begin your semi-annual Turbo premium membership at the discounted rate of $35.99. Your membership will automatically renew six months. You may cancel at any time in your profile. ',
      publicPremiumPlan1:
        'By completing enrollment, you agree to begin your 7-day Turbo premium trial membership. You can cancel your trial at anytime. If you do not cancel your trial membership at least 24 hours before the end of the trial period, your credit card will be charged the promotional Turbo premium membership rate of $9.57/mo and charged on a recurring monthly basis. If you would like to cancel, please review our ',
      publicPremiumPlan2:
        ' for the appropriate steps depending on the type of account(s) you have with Credit Sesame.',
      premiumPlusOneWeekTrial:
        'By completing enrollment, you agree to begin your 7-day Premium + trial membership. Billing begins at the end of your free trial and will automatically renew every month at the standard rate of $15.99. You may cancel at any time in your profile. ',
      publicPremiumPlusOneWeekTrial:
        'By completing enrollment, you agree to begin your 7-day Premium+ trial membership. We collect payment information now to contact your issuing bank to make sure your payment is valid and hold the funds to process your charge at the end of your free trial. Billing begins at the end of your free trial and will automatically renew every month at the standard rate of $15.99. You may cancel at any time in your profile. ',
    },
  },
  directDepositInformationHeader: 'Automatically switch your direct deposit!',
  directDepositInformationSubheader:
    'With ClickSwitch, moving direct deposits to your Sesame Cash account is quicker, easier, and more secure. Automatically switch your payroll, benefit claims, and more to start getting your deposits up to 2-days early—absolutely free. ',
  directDepositInformation_manual_header: 'Set up direct deposit manually',
  directDepositInformation_manual_subheader:
    "You can always set up direct deposit manually by providing your depositor with your Sesame Cash account info below. You'll still get your direct deposits up to 2-days early! ",
  directDepositInformation_manual_disclaimer:
    "Payroll deposit and earlier availability of funds is subject to payer's support of the feature and timing of payer's funding. You may see a message referencing our partner Community Federal Savings Bank (CFSB) when you transfer funds to Sesame Cash.",
  rentReporting: {
    statuses: {
      IN_PROGRESS__LANDLORD_DETAILS_NEEDED: {
        title: "You're almost done: verify your landlord's info!",
        subTitle:
          "We'll verify your rental info with your landlord and automatically find payments you can report to the bureaus!",
        button: 'Set Up Rent Reporting Now',
        action: 'AUTHENTICATION',
        tracking: 'Go to My Landlord information',
      },
      IN_PROGRESS__RENTAL_DETAILS_NEEDED: {
        title: 'Rent reporting: find your payments',
        subTitle:
          "Tell us a bit about your rental— we'll start finding payments you can report to the bureaus!",
        button: 'Find Rent Payments',
        action: 'AUTHENTICATION',
        tracking: 'Go to My Rental information',
      },
      IN_PROGRESS__BANK_ACCOUNT_NOT_LINKED: {
        title: 'Connect your bank account to find your rent payments',
        subTitle:
          "Quickly and securely connect the bank account you use to make your rent payments. We'll automatically find your rent payments to report to the credit bureaus!",
        button: 'Connect your bank account',
        action: 'AUTHENTICATION',
        tracking: 'Link Your Bank Account',
        vertical: BANKING_VERTICAL,
      },
      IN_PROGRESS__BANK_ACCOUNT_LINKED: {
        title: 'Finish setting up rent reporting!',
        subTitle:
          "We'll find monthly rent payments to report to the credit bureaus—including payments you've already made! ",
        button: 'Find your rent payments',
        action: 'AUTHENTICATION',
        tracking: 'Go to Report Your Payments',
      },
      IN_PROGRESS__IDENTIFY_PAYMENTS__DISCONNECTED_BANK: {
        title: 'Bank Account Disconnected',
        subTitle: 'Your bank account for rent reporting has been disconnected',
        button: "Let's Try and Fix It",
        action: 'AUTHENTICATION',
        tracking: 'Go to My Linked Accounts',
      },
      IN_PROGRESS__IDENTIFY_PAYMENTS__NOT_DISCONNECTED_BANK: {
        title: 'Last step—finish identifying rent payments to report',
        subTitle:
          "We'll verify your rent payments with your landlord and start reporting them to the credit bureaus!",
        button: 'Find Rent Payments',
        action: 'AUTHENTICATION',
        tracking: 'Identify Your Rent Payments',
      },
      IN_PROGRESS__READY_FOR_VERIFICATION__DISCONNECTED_BANK: {
        title: 'Please link a bank account',
        subTitle:
          'Your bank account is no longer connected to Credit Sesame. Please click on "Linked Bank Accounts" to re-connect your account.',
        button: "Let's Try and Fix It",
        action: 'ACCOUNTS',
        tracking: 'Go to My Linked Accounts',
      },
      IN_PROGRESS__READY_FOR_VERIFICATION__NOT_DISCONNECTED_BANK: {
        title: "We're verifying your rental information",
        subTitle:
          "Once verified, we'll start reporting your rent payments to the credit bureaus. You should see them reflected on your credit report in about 10 days!",
        button: '',
      },
      IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__DISCONNECTED_BANK: {
        title: 'Please link a bank account',
        subTitle:
          'Your bank account is no longer connected to Credit Sesame. Please click on "Linked Bank Accounts" to re-connect your account.',
        button: "Let's Try and Fix It",
        action: 'AUTHENTICATION',
        tracking: 'Go to My Linked Accounts',
      },
      IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__NOT_DISCONNECTED_BANK: {
        title: "We're still verifying your rental information",
        subTitle:
          "Your landlord hasn't verified your rental information yet. Once verified, we'll start reporting your rent payments to the credit bureaus.",
        button: '',
      },
      IN_PROGRESS__WAITING_FOR_INFORMATION__DISCONNECTED_BANK: {
        title: 'Please link a bank account',
        subTitle:
          'Your bank account is no longer connected to Credit Sesame. Please click on "Linked Bank Accounts" to re-connect your account.',
        button: "Let's Try and Fix It",
        action: 'AUTHENTICATION',
        tracking: 'Go to My Linked Accounts',
      },
      IN_PROGRESS__WAITING_FOR_INFORMATION__NOT_DISCONNECTED_BANK: {
        title: 'Action needed: upload your document',
        subTitle:
          'We sent you an email requesting a document to help verify your rental information. Please upload your document to continue setting up rent reporting.',
        button: 'Upload your document',
        action: 'DOCUMENTS',
        tracking: 'Upload Your Information',
      },
      IN_PROGRESS__VOR_SENT: {
        title: 'We are currently verifying your rental information.',
        subTitle:
          'Once verified we can report your data to the bureaus and you should see a positive impact on your credit score in 10 days.',
      },
      IN_PROGRESS__CALL_TO_VERIFY: {
        title: 'Verifying Your Payment',
        subTitle:
          'We have been unable to verify your rent payments and are calling your landlord to complete payment verification as quickly possible to get your rent payments reported to the credit bureaus.',
      },
      IN_PROGRESS__ESCALATED_VOR: {
        title: 'We are currently verifying your rental information.',
        subTitle:
          'The verification of your current rent payment is taking a bit longer than expected. Once verified we can report your payment to the credit bureaus.',
      },
      IN_PROGRESS__UNABLE_TO_VERIFY: {
        title: 'We are unable to verify your rental property or payment',
        subTitle:
          'We have attempted to verify your rental details, but have been unable to do so.  At this time, we are not able to report your rent payments to the credit bureaus.  Please contact customer support with any questions 1-855-799-9111',
        button: 'Contact Customer Support',
      },
      IN_PROGRESS__WAITING_FOR_PAYMENT: {
        title: 'Please Review Your Submitted Rent Payment',
        subTitle:
          'The rent payment that you submitted to us for verification does not match your stated rental amount.  Please use the button below to identify your most recent rent payment for verification and then submission to the credit bureaus.',
        button: 'Identify Payment',
        action: 'AUTHENTICATION',
      },
      ENROLLED__READY_FOR_SUBMISSION: {
        title: "You're all set!",
        subTitle:
          "We've verified your rental info and are submitting your payments to the credit bureaus. You should see them reflected on your credit report in about 10 days!",
        button: '',
      },
      ENROLLED__WAITING_FOR_PAYMENT__DISCONNECTED_BANK: {
        title:
          'IMPORTANT: Your bank account is no longer linked to Credit Sesame! To report your rent payment each month, your bank account must remain linked.',
        button: 'Let’s Fix This!',
        action: 'ACCOUNTS',
      },
      ENROLLED__WAITING_FOR_PAYMENT__NOT_MISSING_PAYMENTS: {
        title:
          "Your current month's Rent has been reported to the Credit Bureaus!",
        subTitle:
          'Once we identify your next rent payment, it will be automatically submitted to the credit bureaus',
        button: '',
      },
      ENROLLED__WAITING_FOR_PAYMENT__MISSING_PAYMENTS: {
        title: 'Did you make a rent payment?',
        subTitle:
          "We're having trouble finding a rent payment to report this month. ",
        button: 'Find your payment',
        action: 'PAYMENTS',
        tracking: 'Go to My Rental Properties',
      },
      ENROLLED__READY_FOR_VERIFICATION: {
        title: "We're verifying this month's rent payment",
        subTitle:
          "Once verified, we'll report your rent payment to the credit bureaus.",
        button: '',
      },
      ENROLLED__READY_FOR_VERIFICATION__DISCONNECTED_BANK: {
        title:
          'IMPORTANT: Your bank account is no longer linked to Credit Sesame! To report your rent payment each month, your bank account must remain linked.',
        button: 'Let’s Fix This!',
        action: 'ACCOUNTS',
      },
      ENROLLED__PUSHED_TO_SECONDARY_VERIFICATION: {
        title: "We're still verifying this month's rent payment",
        subTitle:
          "It's taking a little little longer than expected—but we're working on it. Once verified, we'll start reporting your rent payments to the credit bureaus.",
        button: '',
      },
      ENROLLED__WAITING_FOR_INFORMATION: {
        title: 'Action needed: upload your document',
        subTitle:
          'We sent you an email requesting a document to help verify your rental information. Please upload your document to continue setting up rent reporting.',
        button: 'Upload your document',
        action: 'DOCUMENTS',
        tracking: 'Upload Your Information',
      },
      ENROLLED__CALL_TO_VERIFY__MISSING_PAYMENTS: {
        title: 'Verifying Your Payment',
        subTitle:
          'We have been unable to verify your current rent payment and are calling your landlord to complete this months payment verification as quickly possible to get your payment reported to the credit bureaus.',
        button: '',
      },
      ENROLLED__CALL_TO_VERIFY__DISCONNECTED_BANK: {
        title:
          'IMPORTANT: Your bank account is no longer linked to Credit Sesame! To report your rent payment each month, your bank account must remain linked.',
        button: 'Let’s Fix This!',
        action: 'ACCOUNTS',
      },
      ENROLLED__VOR_SENT: {
        title: "We are currently verifying your current month's rent payment.",
        subTitle:
          'Once verified we can report your data to the bureaus and you should see a positive impact on your credit score in 10 days.',
      },
      ENROLLED__ESCALATED_VOR: {
        title: "We are currently verifying your current month's rent payment.",
        subTitle:
          'The verification of your current rent payment is taking a bit longer than expected. Once verified we can report your payment to the credit bureaus.',
      },
      ENROLLED__UNABLE_TO_VERIFY: {
        title: 'We are unable to verify your current rent payment',
        subTitle:
          'We have attempted to verify your current rent payment but have been unable to do so. At this time, we are not able to report this payment to the credit bureaus. Please contact customer support with any questions 1-855-799-9111',
        button: 'Contact Customer Support',
      },
      UNENROLLED__VOR_REJECTED: {
        title: 'We are unable to verify your rental property or payment',
        subTitle:
          "We can't report your rent payments to the credit bureaus without verifying details about your rental first. Please contact customer support for more details and next steps.",
        button: 'Contact Customer Support',
      },
      STATUS_NOT_FOUND: {
        title: '',
        subTitle: '',
        button: '',
      },
      CONGRATS: {
        title: 'Congrats!',
        subTitle:
          "You're on the right track to building your credit history. A new tradeline was added to your credit report for your rent payments. You're doing great! Keep it up!",
        button: '',
      },
    },
    cardConfig: {
      stepOne: {
        iconClass: 'rentPaymentCardIcon',
        title: "Submit your Landlord's Information",
        text: ' ',
      },
      stepTwo: {
        iconClass: 'rentPaymentCardIcon',
        title: 'Tell us about your rental payments',
        text: ' ',
      },
      stepThree: {
        iconClass: 'rentPaymentCardIcon',
        title: 'Link your bank account',
        text: ' ',
      },
      stepFour: {
        iconClass: 'rentPaymentCardIcon',
        title: 'We’ll take care of the rest!',
        text: ' ',
      },
    },
  },
  premium: {
    cancelReasons: {
      reason1: "It's too expensive",
      reason2: "I don't use it enough",
      reason3: 'Too many emails',
      reason4: "I'm having technical problems",
    },
  },
  passwordReset: {
    needHelp: {
      forgotMyPassword: {
        tracking: 'Forgot my password',
        url: '/needHelp/forgotPassword',
      },
      forgotMyEmail: {
        tracking: 'Forgot my email',
        url: '/needHelp/forgotEmail',
      },
      haveNoAccess: {
        tracking: 'I have no access',
        url: '/needHelp/haveNoAccess',
      },
    },
    forgotMyPassword: {
      chooseMethod: {
        email: 'email',
        phone: 'phone',
      },
    },
    forgotMyEmail: {
      verifyIdentity: {
        title: 'Verify Identity',
      },
    },
    haveNoAccess: {
      haveNoAccessEmail: {
        tracking: 'Have no access - email',
        url: '/needHelp/haveNoAccess/email',
      },
      haveNoAccessPhone: {
        tracking: 'Have no access - phone',
        url: '/needHelp/haveNoAccess/phone',
      },
      haveNoAccessBoth: {
        tracking: 'Have no access - both',
        url: '/needHelp/haveNoAccess/emailAndPhone',
      },
    },
  },
  prefilledSignup: {
    address: {
      previousAddressError:
        "The previous address you provided is the same as your current address. Please enter a different address, or if you've lived at this address for more than six months, select the Yes button.",
    },
  },
  socialTicker: {
    signupsAmount: 25,
  },
};

export default t;

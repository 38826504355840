import SpreadBox from 'components/Containers/SpreadBox';
import { isNully } from 'helpers/objectHelpers';
import { useEffect, useState } from 'react';
import { ReactComponent as ChevronDown } from 'style/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'style/icons/chevron-up.svg';
const ChangeIcon = props => {
  if (!props.isOpen) {
    return <ChevronDown aria-label="ChevronDown" />;
  } else {
    return <ChevronUp aria-label="ChevronUp" />;
  }
};

const Accordion = props => {
  const [isOpen, setIsOpen] = useState(props.openByDefault);

  useEffect(() => {
    if (props?.scrollState) {
      setIsOpen(false);
    }
  }, [props.scrollState]);

  const onClick = () => {
    if (!isNully(props.isOpen)) {
      if (props.onClickOpen) {
        props.onClickOpen(!props.isOpen);
      }
    } else {
      setIsOpen(prevState => {
        const newState = !prevState;
        return newState;
      });
    }
  };
  return (
    <div>
      <SpreadBox onClick={onClick} className={props.titleClassName}>
        {props.title}
        <ChangeIcon isOpen={isOpen} />
      </SpreadBox>
      <div className="padT8" style={{ display: isOpen ? 'block' : 'none' }}>
        {props.body}
      </div>
    </div>
  );
};

Accordion.defaultProps = {
  openByDefault: false,
};

export default Accordion;

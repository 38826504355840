import React from 'react';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button/index';
import { ResponsiveModal } from 'components/Modals/ResponsiveModal';
import { useState, useEffect } from 'react';
import { padSingleDigitWithLeadingZero } from 'helpers/number';
import './SessionTimeout.scss';
import { getSessionTimeout } from 'helpers/sessionLocalStorageReader.helper';
import moment from '../../../../node_modules/moment/moment';

const Timer = props => {
  const [timeLeft, setTimeLeft] = useState(props.seconds);
  const startTimer = () => {
    const intervalId = setInterval(() => {
      // This timer can be throttled by the browser, so we need to check if the session has expired
      // We don't know any what point the browser would throttle the timer, so we are checking on every tick/second countdown
      if (moment().isAfter(getSessionTimeout())) {
        props.endSession('SESSION_TIMEOUT');
      }
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return function () {
      clearInterval(intervalId);
    };
  };

  const intervalTimer = startTimer();

  useEffect(() => {
    if (!timeLeft) {
      props.endSession('SESSION_TIMEOUT');
    }
    return intervalTimer;
  }, [intervalTimer, props, timeLeft]);

  const timerOn = timeLeft > 0;
  const minutes = padSingleDigitWithLeadingZero(
    Math.floor((timeLeft / 60) % 60),
  );
  const seconds = padSingleDigitWithLeadingZero(Math.floor(timeLeft % 60));

  return (
    <div
      datatestid={`${props.datatestid}-sessionTimeoutModalTimer`}
      className="padTB26 text-48 bold"
    >
      {timerOn && (
        <span>
          {minutes} : {seconds}
        </span>
      )}
    </div>
  );
};

const SessionTimeoutModalFooter = ({
  onContinueSessionTimeoutModal,
  onLogOutSessionTimeoutModal,
}) => {
  return (
    <FlexContainer className="justify-end flex-mobile-col">
      <div className="padL8 mobileOnlyHide" />
      <Button mrphType="destructiveLink" onClick={onLogOutSessionTimeoutModal}>
        Log Out
      </Button>
      <div className="padL8 m-padB8" />
      <Button mrphType="primary" onClick={onContinueSessionTimeoutModal}>
        Continue Session
      </Button>
    </FlexContainer>
  );
};

const SessionTimeoutModalTitle = () => {
  return 'Continue your session?';
};

const SessionTimeoutModalDescription = () => {
  return 'You are about to be logged out due to inactivity.';
};

const SessionTimeoutModalTimer = props => {
  return (
    <FlexContainer className="justify-between">
      <Timer {...props} />
    </FlexContainer>
  );
};

export const SessionTimeoutModalBody = props => {
  return (
    <>
      <FlexContainer className="justify-between m-justify-center">
        <FlexContainer className="flex-column">
          <div className="padB16" />
          <Label
            datatestid={`${props.datatestid}-sessionTimeoutModalTitle`}
            type="H2"
            typeHeader={'h2'}
            className="mH4"
          >
            <SessionTimeoutModalTitle />
          </Label>
          <div className="padB16" />
          <Label
            datatestid={`${props.datatestid}-sessionTimeoutModalDescription`}
            className="text-18 mBody-2 notBold"
          >
            <SessionTimeoutModalDescription />
          </Label>
          <div className="padB4" />
        </FlexContainer>
        <div className="padB24" />
      </FlexContainer>
      <FlexContainer
        datatestid={`${props.datatestid}-sessionTimeoutModalTimer`}
        className="justify-center H4 m-H4 bold"
      >
        <SessionTimeoutModalTimer {...props} />
      </FlexContainer>
    </>
  );
};

const SessionTimeoutModal = props => {
  return (
    <>
      <ResponsiveModal
        visible={props.sessionTimeoutModalOpen}
        datatestid={props.datatestid}
        footer={
          <SessionTimeoutModalFooter
            onContinueSessionTimeoutModal={props.onContinueSessionTimeoutModal}
            onLogOutSessionTimeoutModal={props.onLogOutSessionTimeoutModal}
          />
        }
        wrapClassName="sessionTimeout"
        width="540px"
        onCancel={props.onCloseSessionTimeoutModal}
        maskClosable={false}
      >
        <SessionTimeoutModalBody {...props} />
      </ResponsiveModal>
    </>
  );
};

export { SessionTimeoutModal };

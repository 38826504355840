import {
  isInvisibleUser,
  isPendingPaymentOnSignUp,
  isPremium,
} from 'helpers/userHelper';
import * as userConstants from 'types/userInfo.constants';
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from 'types/app.constants';
import {
  signupConstants,
  ssnExistsData,
  SMARTY_STREETS_VALIDATION,
  USER_VALIDATION,
  ADDRESS,
  PHONE_NUMBER,
  SSN,
  VALIDATION,
  QUESTIONS,
  SUMMARY,
  QUESTIONS_SSN_MATCH,
  BOOSTER_ENROLL,
  DUP_KBA_PHONE_VERIFICATION,
  ACCT_CREATION,
  BOOSTER_SUCCESS_LANDING,
  REVALIDATE_EMAIL,
  otpValidation,
  SELF_LOAN_TU_ENROLL,
} from '../types/signup.constants';
import {
  userService,
  patchUser,
  getAffiliatePixels,
  updateUserCredentials,
  verifyUserSSN,
} from 'externals/_services/user.service';
import {
  setFirstNameUser,
  setUserLogoutTime,
  setResurrectedUser,
  setItem,
  getItem,
  removeItem,
} from 'helpers/localstorage';
import { isUserRegisteredBefore } from 'helpers/userHelper';
import { visitorReportError } from 'externals/_tracking/mixpanel/mixpanel.track';
import { mixpanelEvents } from 'externals/_tracking/mixpanel/Mixpanel.events.config';
import {
  initUserTracking,
  reportClicks,
  OPT_SIGNUP_ACCOUNT_CREATED,
  OPT_SIGNUP_COMPLETE,
} from '../signup.tracking';
import * as UAParser from 'ua-parser-js';
import { reportOptimizely } from 'externals/_tracking/optimizely';
import moment from 'moment';
import { getUserInfo } from 'actions/userInfo.actions';
import { brazeEventTrack } from 'externals/_tracking/braze/Braze.track';
import { retrieveCreditProfile } from 'actions/creditInfo.actions';
import {
  getAttributesForRegister,
  resolveBooleanToYesOrNo,
  buildUserMeta,
} from 'helpers/tracking.helpers';
import {
  manuallyActivatePage,
  pollForExp,
} from 'externals/_tracking/optimizely/optimizely.util';
import {
  triggerClientPixel,
  triggerClientPixelForInvisibleRegistration,
  triggerResurrectedUser,
} from 'helpers/pixelHelper';
import { pushUserProfileOptAttributes } from 'actions/login.actions';
import { createLogger } from 'helpers/logger';
import {
  ENTER_CODE,
  CHOOSE_DELIVERY_METHOD,
  ENTER_PASSCODE,
} from 'pages/SignupPage/types/otp.constants';
import {
  GENERAL,
  GENERAL_TU,
  DEVICE_BLOCKED,
  DUP_ERROR,
  ACCOUNT_LOCKOUT,
  CREDIT_PROFILE_ERR,
  QUESTIONS_NOT_RECEIVED,
} from '../SignupErrorPage/types/signupError.constants';
import { checkForValidAudienceForCashFundingExp } from 'pages/SignupPage/actions/cash_experiment_check.actions';
import { getTUTrustEvSessionId } from 'externals/_tracking/trustev';
import { SIGNUP_DUP_SSN_KBA_VERIFY_PHONE } from 'externals/_tracking/optimizely/optimizely.exp.constants';
import {
  deleteCookieValueForCSDomain,
  getCookieValue,
  setCookieWithExpiration,
} from 'helpers/cookie.helper';
import { wrapService } from 'actions/service_wrapper.actions';
import { enrollLater } from 'externals/_services/sesameCash.service';
import { CATEGORY_CUSTOM_ALERTS } from 'types/tracking.constants';
import {
  getCommonTrackingD2PAttributes,
  getOneClickSignupTracking,
} from 'helpers/oneClickSignup.helpers';
import { optimizelyClient } from 'externals/_tracking/optimizely/optFlags';
import {
  DUPE_SSN_FLOW,
  INVISIBLE_FLOW,
  ocsFlowTracking,
} from '../OneClickSignup/OneClickSignup.constants';
import {
  BUREAU_GET_QS_FAILED,
  NOT_FOUND_IN_BUREAU,
  SSN_VERIFICATION_FAILED,
} from 'types/signupStatus.constants';
import {
  CLICK,
  LOGIN,
  REGISTER,
  SIGNUP_ACCOUNT_CREATED,
  SIGNUP_FINISH,
} from 'externals/_tracking/types/eventTypes.constants';
import { standardEventHandler } from 'actions/eventHandler.actions';
import { identifyUser } from 'externals/_tracking/impact/impact.track';
import { useE2EAcquisition } from 'hook/e2e_acquisition.hook';
import { campaignIdAssociatedCopies } from 'types/e2e.constants';
import { trackSignupForTvScientific } from 'externals/_tracking/tvScientific/tv_scientific.track';
import { getMarketingUrlE2eSignUpRedirect } from 'helpers/marketing.helper';
import {
  OVERVIEW_URL,
  OVERVIEW_WELCOME_URL,
} from 'types/mrph.routes.constants';
import { LOAD_D2P_DATA } from 'types/d2p.constants';
import { updatePassword as updatePasswordOneClickSignup } from 'pages/SignupPage/OneClickSignup/OneClickTUSignup.actions';

const logger = createLogger({
  name: 'Signup.Actions',
  categories: ['signup'],
  includeVisitor: true,
});

const s_cashEnrollLater = wrapService(enrollLater, {
  name: 's_cashEnrollLater',
});

export const requestUpdateUser = () => {
  return { type: signupConstants.UPDATE_USER_REQUEST };
};

export const successUpdateUser = () => {
  return { type: signupConstants.UPDATE_USER_SUCCESS };
};

export const failureUpdateUser = error => {
  return { type: signupConstants.UPDATE_USER_FAIL, error };
};

export const successQuestions = questions => {
  return { type: signupConstants.GET_QUESTIONS_SUCCESS, questions };
};

export const setShowOTP = state => {
  return { type: signupConstants.SHOW_OTP, ...state };
};

export const setShowKBA = state => {
  return { type: signupConstants.SHOW_KBA, ...state };
};

export const setOTPExp = state => {
  return { type: signupConstants.OTP_EXP, ...state };
};

export const dupeSSN = ssnExistData => dispatch => {
  dispatch(updateStep('GOTO', 'ssn'));
  dispatch({ type: signupConstants.SSN_EXIST_USER, ssnExistData });
};

export const dupeSSNOtp = (ssnExistData, otpResult) => dispatch => {
  let data = {
    isDupeSSNOtp: true,
    ssnExists: false,
    loading: false,
  };
  if (ssnExistData) {
    data.ssnData = ssnExistData;
  }
  if (otpResult) {
    data.dupeSSNOTP = otpResult;
  }
  dispatch({ type: signupConstants.SSN_EXIST_OTPCODE_SUCCESS, data });
};

export const revalidateEmail = () => dispatch => {
  const isSSNExist = JSON.parse(getItem(ssnExistsData));
  if (isSSNExist) {
    dispatch(getDupSSNQuestions(isSSNExist));
  } else {
    dispatch(getQuestions('all'));
  }
};

export const getFreshUserInfo = response => async dispatch => {
  try {
    const userInfo = await dispatch(getUserInfo(true));
    return { userInfo, response };
  } catch (err) {
    dispatch(errorLogout(GENERAL));
    logger.reportAPIError(err);
  }
};

const createUser =
  (user, history, isPasswordless) =>
  async (dispatch, getState, setCSContext) => {
    let email = user.email;
    let pwd = user.password;
    const isEncryptedEmail = user.encryptedEmail;
    const signupPageError = { type: 'signupErrorPage' };
    let nestedError = null;
    dispatch(request());
    let createUserDataCollector = {};
    try {
      const registrationResponse = await userService.register(user);
      const isNewlyRegistered =
        registrationResponse.userId && registrationResponse.userId !== null;
      if (isPasswordless) {
        pwd = registrationResponse.password
          ? registrationResponse.password
          : 'default';
        setItem('passwordStep', JSON.stringify({ tempPass: pwd }));
      }
      if (isEncryptedEmail) {
        email = registrationResponse.email;
      }
      setItem('POD1-1750_audience', 'true');
      //login user
      dispatch({ type: LOGIN_REQUEST, email: email });
      createUserDataCollector.csApiSessionId = getCookieValue('cs-api-sessid');
      try {
        const auth = await userService.login(
          email,
          pwd,
          null,
          createUserDataCollector.csApiSessionId,
        );
        if (createUserDataCollector.csApiSessionId) {
          deleteCookieValueForCSDomain('cs-api-sessid');
        }
        //set session timeout
        const t = moment();
        t.add(auth.expiresSeconds, 's');
        setUserLogoutTime(t.format());

        dispatch({ type: LOGIN_SUCCESS, user: { ...auth } });

        //start tracking
        initUserTracking(
          registrationResponse,
          auth.userId,
          auth.sessionIdentifier,
        );
        try {
          const userInfo = await userService.get();
          dispatch({ type: userConstants.GET_SUCCESS, userInfo });
          setCSContext(getState());
          setItem(
            'isTestAccount',
            resolveBooleanToYesOrNo(userInfo?.testAccount),
          );

          //if active user re-direct them to dashboard if email/password match
          if (userInfo.status === 'active') {
            if (auth.isUserResurrected) {
              triggerResurrectedUser();
            }
            setResurrectedUser(auth.isUserResurrected);
            console.log('Braze Event', 'Log In', {});
            brazeEventTrack('Log in', {});
            dispatch(
              standardEventHandler(LOGIN, {
                'Logged In': 'Yes',
                forceSendUnified: true,
              }),
            );
            dispatch(redirectExistingUser(userInfo, history));
          }
          //take them to correct step in signup
          else if (userInfo.status === 'pending') {
            if (isNewlyRegistered) {
              console.log('Braze Event', 'Sign Up', {});
              brazeEventTrack('Sign Up', {});
            } else {
              console.log('Braze Event', 'Log In', {});
              brazeEventTrack('Log in', {});
            }
            dispatch(
              standardEventHandler(SIGNUP_ACCOUNT_CREATED, {
                'Sendex Score': registrationResponse.sendexScore,
                forceSendUnified: true,
              }),
            );
            reportOptimizely(OPT_SIGNUP_ACCOUNT_CREATED);
            if (!isEncryptedEmail) {
              reportOptimizely('signup1_submit');
              dispatch(standardEventHandler(CLICK, { 'Click Type': 'Next' }));
              dispatch(reportClicks(ACCT_CREATION));
            }
            return 'success';
          } else if (
            userInfo.status === 'blocked' ||
            userInfo.status === 'deceased'
          ) {
            //iovationFailed
            dispatch(errorLogout(DEVICE_BLOCKED));
            nestedError = signupPageError;
          } else {
            dispatch(errorLogout(GENERAL));
            nestedError = signupPageError;
          }
        } catch (error) {
          dispatch({ type: userConstants.GET_FAILURE, error });
          dispatch(errorLogout(GENERAL));
          logger.reportAPIError(error);
          nestedError = signupPageError;
        }
      } catch (error) {
        dispatch({ type: LOGIN_FAILURE });
        dispatch({
          type: signupConstants.SIGNUP_LOGIN_FAILURE,
          error,
        });
        logger.reportAPIError(error);
        if (error.responseCode === 403) {
          let errorMessage = error.error.toString();
          if (errorMessage === 'API Key is inactive.') {
            errorMessage = 'Something went wrong.';
          }
          dispatch(errorLogout(ACCOUNT_LOCKOUT, errorMessage));
          nestedError = signupPageError;
        } else {
          if (isEncryptedEmail) {
            error.email = email;
          }
          nestedError = error;
        }
      }
    } catch (error) {
      const isKickboxValidation = !!user.verifyEmail;
      let finalError = { ...error };
      if (error.message && error.message.toString() === 'Failed to fetch') {
        finalError.message =
          'Sorry, something went wrong on our end. Please try again later.';
      } else if (isKickboxValidation) {
        if (error.code === 'EM_1004' || error.code === 'EM_1003') {
          finalError.type = 'kickbox';
        } else if (error.code === 'B_A_706' || error.code === 'ENC_1003') {
          finalError.type = 'kickbox';
          finalError.message = 'Please enter a valid email.';
        } else if (error.code === 'SU_1062') {
          finalError.type = 'kickbox';
          finalError.message =
            'Please enter a valid email address. We were not able to verify the email address you entered.';
        }
      } else if (!error.message) {
        finalError.message = error.error.toString();
      }

      dispatch(failure(error));
      logger.reportAPIError(error);
      throw finalError;
    }

    if (nestedError) {
      throw nestedError;
    }

    function request() {
      return { type: signupConstants.CREATE_USER_REQUEST };
    }

    function failure(error) {
      return { type: signupConstants.CREATE_USER_FAIL, error };
    }

    function redirectExistingUser(userInfo, history) {
      return dispatch => {
        dispatch(standardEventHandler('Email in use'));
        history.push(OVERVIEW_URL);
      };
    }
  };

const createUserForTUErr =
  (user, history, isPasswordless) =>
  async (dispatch, getState, setCSContext) => {
    let email = user.email;
    let pwd = user.password;
    const isEncryptedEmail = user.encryptedEmail;
    const signupPageError = { type: 'signupErrorPage' };
    let nestedError = null;
    let createUserDataCollector = {};
    try {
      const registrationResponse = await userService.register(user);
      if (registrationResponse.error) {
        throw registrationResponse;
      }
      const isNewlyRegistered =
        registrationResponse.userId && registrationResponse.userId !== null;
      if (isPasswordless) {
        pwd = registrationResponse.password
          ? registrationResponse.password
          : 'default';
        setItem('passwordStep', JSON.stringify({ tempPass: pwd }));
      }
      if (isEncryptedEmail) {
        email = registrationResponse.email;
      }
      createUserDataCollector.csApiSessionId = getCookieValue('cs-api-sessid');
      try {
        const auth = await userService.login(
          email,
          pwd,
          null,
          createUserDataCollector.csApiSessionId,
        );
        if (createUserDataCollector.csApiSessionId) {
          deleteCookieValueForCSDomain('cs-api-sessid');
        }
        //set session timeout
        const t = moment();
        t.add(auth.expiresSeconds, 's');
        setUserLogoutTime(t.format());
        //start tracking
        initUserTracking(
          registrationResponse,
          auth.userId,
          auth.sessionIdentifier,
        );
        try {
          const userInfo = await userService.get();
          dispatch({ type: userConstants.GET_SUCCESS, userInfo });
          setCSContext(getState());
          setItem(
            'isTestAccount',
            resolveBooleanToYesOrNo(userInfo?.testAccount),
          );

          //if active user re-direct them to dashboard if email/password match
          if (userInfo.status === 'active') {
            if (auth.isUserResurrected) {
              triggerResurrectedUser();
            }
            setResurrectedUser(auth.isUserResurrected);
            console.log('Braze Event', 'Log In', {});
            brazeEventTrack('Log in', {});
            dispatch(
              standardEventHandler(LOGIN, {
                'Logged In': 'Yes',
                forceSendUnified: true,
              }),
            );
            dispatch(redirectExistingUser(userInfo, history));
          }
          //take them to correct step in signup
          else if (userInfo.status === 'pending') {
            if (isNewlyRegistered) {
              console.log('Braze Event', 'Sign Up', {});
              brazeEventTrack('Sign Up', {});
            } else {
              console.log('Braze Event', 'Log In', {});
              brazeEventTrack('Log in', {});
            }
            dispatch(
              standardEventHandler(SIGNUP_ACCOUNT_CREATED, {
                'Sendex Score': registrationResponse.sendexScore,
                forceSendUnified: true,
              }),
            );
            reportOptimizely(OPT_SIGNUP_ACCOUNT_CREATED);
            if (!isEncryptedEmail) {
              reportOptimizely('signup1_submit');
              dispatch(standardEventHandler(CLICK, { 'Click Type': 'Next' }));
              dispatch(reportClicks(ACCT_CREATION));
            }
            return 'success';
          } else if (
            userInfo.status === 'blocked' ||
            userInfo.status === 'deceased'
          ) {
            //iovationFailed
            dispatch(errorLogout(DEVICE_BLOCKED));
            nestedError = signupPageError;
          } else {
            dispatch(errorLogout(GENERAL));
            nestedError = signupPageError;
          }
        } catch (error) {
          dispatch({ type: userConstants.GET_FAILURE, error });
          dispatch(errorLogout(GENERAL));
          logger.reportAPIError(error);
          nestedError = signupPageError;
        }
      } catch (error) {
        dispatch({ type: LOGIN_FAILURE });
        dispatch({
          type: signupConstants.SIGNUP_LOGIN_FAILURE,
          error,
        });
        logger.reportAPIError(error);
        if (error.responseCode === 403) {
          let errorMessage = error.error.toString();
          if (errorMessage === 'API Key is inactive.') {
            errorMessage = 'Something went wrong.';
          }
          dispatch(errorLogout(ACCOUNT_LOCKOUT, errorMessage));
          nestedError = signupPageError;
        } else {
          if (isEncryptedEmail) {
            error.email = email;
          }
          nestedError = error;
        }
      }
    } catch (error) {
      const isKickboxValidation = !!user.verifyEmail;
      let finalError = { ...error };
      if (error.message && error.message.toString() === 'Failed to fetch') {
        finalError.message =
          'Sorry, something went wrong on our end. Please try again later.';
      } else if (isKickboxValidation) {
        if (error.code === 'EM_1004' || error.code === 'EM_1003') {
          finalError.type = 'kickbox';
        } else if (error.code === 'B_A_706' || error.code === 'ENC_1003') {
          finalError.type = 'kickbox';
          finalError.message = 'Please enter a valid email.';
        } else if (error.code === 'SU_1062') {
          finalError.type = 'kickbox';
          finalError.message =
            'Please enter a valid email address. We were not able to verify the email address you entered.';
        }
      } else if (!error.message) {
        finalError.message = error.error.toString();
      }

      dispatch(failure(error));
      logger.reportAPIError(error);
      throw finalError;
    }

    if (nestedError) {
      throw nestedError;
    }

    function failure(error) {
      return { type: signupConstants.CREATE_USER_FAIL, error };
    }

    function redirectExistingUser(userInfo, history) {
      return dispatch => {
        dispatch(standardEventHandler('Email in use'));
        history.push(OVERVIEW_URL);
      };
    }
  };

const isTUEnroll = state => {
  const signup = state.signup;
  return signup && signup.step === SELF_LOAN_TU_ENROLL;
};

const updateProfile = user => async (dispatch, getState, setCSContext) => {
  try {
    if (isTUEnroll(getState())) {
      await userService.update(user);
      await dispatch(getFreshUserInfo());
    } else {
      dispatch(requestUpdateUser(user));
      await userService.update(user);
      await dispatch(getFreshUserInfo());
      dispatch(updateStep('GOTO', ADDRESS));
      dispatch(successUpdateUser());
      setCSContext(getState());
    }
  } catch (error) {
    logger.reportAPIError(error);
    dispatch(failureUpdateUser(error));
    dispatch(signupAlert(error.error.toString()));
    if (isTUEnroll(getState())) throw error;
  }
};

const updatePhoneNumber = phoneNumber => async (dispatch, getState) => {
  try {
    if (isTUEnroll(getState())) {
      await patchUser(phoneNumber);
      await dispatch(getFreshUserInfo());
    } else {
      dispatch(requestUpdateUser(phoneNumber));
      await patchUser(phoneNumber);
      await dispatch(getFreshUserInfo());
      dispatch(updateStep('GOTO', SSN));
      dispatch(successUpdateUser());
    }
  } catch (err) {
    logger.reportAPIError(err);
    dispatch(failureUpdateUser(err));
    dispatch(signupAlert(err.error.toString()));
    if (isTUEnroll(getState())) throw err;
  }
};

const updateUserAddressPatch = address => async dispatch => {
  dispatch(requestUpdateUser());
  await userService.updateUserAddressPatch(address);
  const userInfo = await dispatch(getUserInfo());
  return userInfo;
};

const updateUserSSN =
  (ssn, isFull9Digit, isPasswordless) => async (dispatch, getState) => {
    const comeFromTUEnroll = isTUEnroll(getState());
    let nineDigitText = isFull9Digit ? '9 digit ' : '';
    logger.debug(`Start Submit ${nineDigitText}SSN sequence`);
    try {
      if (!comeFromTUEnroll) {
        dispatch(requestUpdateUser());
      }
      await userService.updateUserSSN(ssn);
      let optSuccessType = 'ssn4_success';
      if (isFull9Digit) {
        optSuccessType = 'ssn9_success';
      }
      reportOptimizely(optSuccessType);

      await dispatch(getFreshUserInfo());
      if (isPasswordless && getItem('passwordStep')) {
        goToPasswordStep(dispatch, false);
      } else {
        const questionsRes = await dispatch(
          getQuestions('all', false, comeFromTUEnroll),
        );
        const isOtpEligible = questionsRes?.isOtpEligible;
        if (isOtpEligible === false) {
          dispatch(setShowOTP({ showOTP: true }));
          setItem(otpValidation, true);
          await dispatch(
            successQuestions({
              ...questionsRes,
              remainingPasscodeAttempts: 3,
              remainingResendPasscodeAttempts: 2,
            }),
          );
        } else {
          await dispatch(successQuestions(questionsRes));
        }
      }
    } catch (error) {
      window.scroll(0, 0);
      logger.reportAPIError(error);
      logger.errorException(
        `Error during Submit ${nineDigitText}SSN sequence`,
        error,
      );
      if (
        error.errorCode === 'SU_1027' ||
        error.errorCode === 'BU_TU_E_801.24'
      ) {
        //failure to update 4 digit - double ssn
        dispatch(failure({ errorCode: error.errorCode }));
        if (comeFromTUEnroll) {
          dispatch(updateSelfLoanForTUErrorsConfig({ lastSsnRequire9: true }));
        }
      } else if (error.errorCode === 'SU_1057') {
        //ssn exist
        const dupData = { ...error };
        setItem(ssnExistsData, JSON.stringify(dupData));
        if (isPasswordless && getItem('passwordStep')) {
          goToPasswordStep(dispatch, true);
        } else {
          if (dupData.fundedUser || dupData.hasLinkedAccounts) {
            dispatch(dupeSSN(dupData));
          } else {
            dispatch(getDupSSNQuestions(dupData));
          }
        }
      } else if (
        error.errorCode === 'SU_1042' ||
        error.errorCode === 'SU_1047'
      ) {
        dispatch(errorLogout(DUP_ERROR));
      } else {
        dispatch(failure({ errorCode: error.errorCode }));
        let finalAlert = '';
        if (error && error.error && error.error.toString) {
          finalAlert = error.error.toString();
        } else if (error && error.message) {
          finalAlert = error.message;
        }
        dispatch(signupAlert(finalAlert));
      }
    }

    function failure(error) {
      return { type: signupConstants.UPDATE_USER_SSN_FAIL, error };
    }
  };

const goToPasswordStep = (dispatch, isSSNExist) => {
  const passwordCacheObj = JSON.parse(getItem('passwordStep'));
  passwordCacheObj.showStep = true;
  passwordCacheObj.isSSNExist = isSSNExist;
  setItem('passwordStep', JSON.stringify(passwordCacheObj));

  dispatch({ type: signupConstants.SET_SIGNUP_STEP, pending: 'password' });
};

const updatePassword = (currentPassword, newPassword) => async dispatch => {
  try {
    dispatch(requestUpdateUser());
    await updateUserCredentials({ currentPassword, newPassword });
    await dispatch(getUserInfo());
    dispatch(successUpdateUser());
    const passwordCacheObj = JSON.parse(getItem('passwordStep'));
    if (passwordCacheObj.isSSNExist) {
      const isSSNExist = JSON.parse(getItem(ssnExistsData));
      dispatch(getDupSSNQuestions(isSSNExist));
    } else {
      dispatch(getQuestions('all'));
    }
    removeItem('passwordStep');
  } catch (err) {
    logger.reportAPIError(err);
    dispatch(failureUpdateUser(err));
    dispatch(signupAlert(err.error.toString()));
  }
};

const storeTuSessionId = tuSessionId => {
  return {
    type: signupConstants.STORE_TU_SESSION_ID,
    tuSessionId,
  };
};

const getTuSessionId = async (getState, dispatch) => {
  const state = getState();
  let tuSessionId = state.signup.tuSessionId;
  if (!tuSessionId) {
    try {
      const TU_SESSION_ID = await getTUTrustEvSessionId();
      dispatch(signupActions.storeTuSessionId(TU_SESSION_ID));
      return TU_SESSION_ID;
    } catch (err) {
      // eslint-disable-next-line no-throw-literal
      throw {
        errorCode: 'SU_1068',
        error: 'TU TrustEV Failure',
      };
    }
  } else {
    return tuSessionId;
  }
};

const processQuestionsReceived = questions => {
  const { isOtpEligible } = questions;
  if (isOtpEligible) {
    let isCodeScreen = false;
    if (questions.items) {
      questions.items.forEach(item => {
        if (item.text === ENTER_PASSCODE) {
          isCodeScreen = true;
        }
      });
    }
    return isCodeScreen ? ENTER_CODE : CHOOSE_DELIVERY_METHOD;
  } else {
    return isOtpEligible;
  }
};

const finalizeQuestionsResponse =
  questionsResponse => async (dispatch, getState) => {
    await dispatch(getFreshUserInfo(questionsResponse));
    dispatch(updateStep('GOTO', 'questions'));
    const otpScreen = processQuestionsReceived(questionsResponse);
    return Object.assign({ otpScreen: otpScreen }, questionsResponse);
  };

const getQuestions =
  (otpFlow, performFinalDispatch = true, comeFromTUEnroll = false) =>
  async (dispatch, getState) => {
    logger.debug(`Start Get Questions sequence`);
    let tuSessionId = null;
    try {
      dispatch(requestQuestions());
      const tuSessionIdRes = await getTuSessionId(getState, dispatch);
      tuSessionId = tuSessionIdRes;
      dispatch(
        standardEventHandler('Log', {
          Message: 'Start Get Questions call',
          'TU Session Id': tuSessionId,
        }),
      );

      let questionsRes = await userService.getQuestions(tuSessionId, otpFlow);
      questionsRes = await dispatch(finalizeQuestionsResponse(questionsRes));
      if (performFinalDispatch) {
        await dispatch(successQuestions(questionsRes));
      }
      return questionsRes;
    } catch (error) {
      const errorCode =
        getState().signup.questionsNotReceived &&
        getState().signup.ssnRetriesCount >= 2
          ? 'questionsNotReceived'
          : error.errorCode;
      dispatch(
        handleErrors(errorCode, error.error.toString(), comeFromTUEnroll),
      );
      logger.reportAPIError(error, 'getquestions');
      logger.errorException(`Error during Get Questions sequence`, error);
      if (
        errorCode !== 'questionsNotReceived' &&
        !isInvisibleUserError(errorCode)
      ) {
        dispatch(failureQuestions(error.error.toString()));
      }
      return null;
    }

    function requestQuestions() {
      return { type: signupConstants.GET_QUESTIONS_REQUEST };
    }

    function failureQuestions(error) {
      return { type: signupConstants.GET_QUESTIONS_FAIL, error };
    }
  };

const getDupSSNQuestions = dupData => async (dispatch, getState) => {
  logger.debug(`Start Get Dup SSN Questions sequence`);
  let tuSessionId = null;
  try {
    dispatch(requestUpdateUser());
    const tuSessionIdRes = await getTuSessionId(getState, dispatch);
    tuSessionId = tuSessionIdRes;
    let questionsRes = await userService.getQuestions(tuSessionId, 'all');
    questionsRes = await dispatch(finalizeQuestionsResponse(questionsRes));
    questionsRes = await dispatch(handleDupKBAVerifyPhoneExp(questionsRes));
    dispatch(successQuestions(questionsRes));
  } catch (err) {
    if (err.isApiError) {
      logger.reportAPIError(err, 'getDupSSNQuestions');
      logger.errorException(`Error during Get Dup SSN Questions sequence`, err);
    }
    if (
      err &&
      (err.errorCode === 'SU_1068' || err.errorCode === 'BU_TU_E_801.21')
    ) {
      dispatch(handleErrors(err.errorCode, err.error.toString()));
    } else {
      dispatch(dupeSSN(dupData));
    }
  }
};

export const finalizeSignupWithoutCash =
  (history, extraProps) => async (dispatch, getState) => {
    let finalUser = getState().userInfo.userInfo;
    try {
      dispatch(requestUpdateUser());
      finalUser = await dispatch(getUserInfo(true));
    } finally {
      dispatch(checkForValidAudienceForCashFundingExp());
      dispatch(transitionOverview(finalUser, history, extraProps));
    }
  };

export const postSignUpHandler =
  (history, userInfo, pageName, isUserResurrected, extraProps) =>
  async (dispatch, getState) => {
    const state = getState();
    const config = state?.config?.config;

    manuallyActivatePage('mrph_signUpFinish');
    const d2pTracking = getCommonTrackingD2PAttributes({
      d2pConfig: state?.d2p,
    });

    const signupFinishDataCollector = { history, dispatch };
    const updatePixelFiredFlag = async _ => {
      try {
        await getAffiliatePixels();
      } catch (err) {
        logger.error('Error updating client pixels "pixelFired" flag', err);
      }
    };

    //Step 2: Send register event with credit info attributes
    const sendRegisterEvent = async () => {
      reportOptimizely('ViewSignupFinish');

      if (!isUserResurrected) {
        await updatePixelFiredFlag();
        triggerClientPixel(userInfo);
      }
      try {
        const [userInfo, creditInfo] = await Promise.all([
          dispatch(getUserInfo(true)),
          dispatch(retrieveCreditProfile()),
        ]);
        signupFinishDataCollector.creditInfo = creditInfo;
        signupFinishDataCollector.userInfo = userInfo;

        //ocs tracking
        const ocsTracking = getOneClickSignupTracking();
        if (ocsTracking && getItem(ssnExistsData)) {
          ocsTracking.current_signup_flow = DUPE_SSN_FLOW;
        }

        const attrs = getAttributesForRegister(creditInfo);
        const userAttrs = buildUserMeta(userInfo);
        const mxpAttrs = Object.assign(
          {
            'Page Name': pageName,
            forceSendUnified: true,
          },
          attrs,
          userAttrs,
          ocsTracking,
        );

        const eventName = 'Register';
        console.log('Braze Event', eventName, attrs);
        console.log('Mxp Event', mixpanelEvents.REGISTER, mxpAttrs);
        //  executing identify user after user finish sign up flow
        identifyUser();
        if (!isUserRegisteredBefore(userInfo)) {
          // executing track SignupForTvScientific for tvScientific
          trackSignupForTvScientific('complete_registration');
        }

        brazeEventTrack(eventName, attrs);
        dispatch(
          standardEventHandler(REGISTER, {
            ...mxpAttrs,
            ...d2pTracking,
          }),
        );
        reportOptimizely(OPT_SIGNUP_COMPLETE);
        pushUserProfileOptAttributes(creditInfo);
      } catch (err) {
        logger.reportAPIError(err, 'postSignupHandler');
      }
    };

    //Step 3: Handle whether user sees cash
    const handleNextTransition = async props => {
      try {
        dispatch(successSubmitQuestions());
        if (config?.appManagement?.isSesameCashEnabled)
          await dispatch(s_cashEnrollLater());
      } catch (err) {
        logger.reportAPIError(err);
      } finally {
        await dispatch(finalizeSignupWithoutCash(history, extraProps));
        if (isPremium(props?.userInfo)) {
          history.push(OVERVIEW_WELCOME_URL);
        } else {
          if (config?.signup?.hasFreeOnboarding) {
            history.push('/dashboard/freeonboarding');
          }
        }
      }
    };

    //signupFinish tasks
    try {
      const freshUserInfo = await dispatch(getUserInfo(true));
      if (isPendingPaymentOnSignUp(freshUserInfo)) {
        dispatch({
          type: LOAD_D2P_DATA,
          postSignUpData: {
            isUserResurrected,
            extraProps,
          },
        });
        history?.replace('/d2p/checkout/signup');
      } else {
        await sendRegisterEvent();
        await handleNextTransition({ userInfo: freshUserInfo });
        await dispatch({ type: LOAD_D2P_DATA, d2pTracking: null });
      }
    } catch (err) {
      logger.logFatalClientError(err);
      logger.reportAPIError(err, 'postSignUpHandler');
      logger.errorException('Error sending register ', err);
    }
  };

const transitionOverview =
  (userInfo, history, extraProps) => (dispatch, getState) => {
    const state = getState();
    const d2pTracking = getCommonTrackingD2PAttributes({
      d2pConfig: state?.d2p,
    });
    const { campaignName, isPageAssociatedWithCampaign } = useE2EAcquisition();
    const mrphSignupFinish = () => {
      const usrAttrs = buildUserMeta(userInfo);
      dispatch(
        standardEventHandler(SIGNUP_FINISH, {
          ...usrAttrs,
          forceSendUnified: true,
          ...d2pTracking,
        }),
      );

      const overviewUrl = isPremium(userInfo)
        ? OVERVIEW_WELCOME_URL
        : OVERVIEW_URL;

      let destinationUrl = getMarketingUrlE2eSignUpRedirect() ?? overviewUrl;

      if (
        isPageAssociatedWithCampaign &&
        campaignName === campaignIdAssociatedCopies?.premium
      ) {
        destinationUrl = '/3breport';
      }

      history.replace(destinationUrl);
      dispatch(finalizeSignupAction());
    };

    // set "usrTkn" cookie for fully registered users
    setCookieWithExpiration('usrTkn', userInfo.userId, 100, null, null, true);
    setFirstNameUser(userInfo.firstName);
    mrphSignupFinish();
  };

const successSubmitQuestions = completeConfig => {
  return { type: signupConstants.SUBMIT_QUESTIONS_SUCCESS, completeConfig };
};
const failureSubmitQuestions = error => {
  return { type: signupConstants.SUBMIT_QUESTIONS_FAIL, error };
};

function PartialQuestionsError(data) {
  this.error = 'Partially correct questions';
  this.data = data;
  this.errorCode = 'SU_1021';
}

const submitKBAQuestions =
  (answers, history, isDupeSSNFlag, extraProps) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const direct2Premium = state?.d2p?.direct2Premium;
      logger.debug(`Start Submit KBA Questions sequence`);
      dispatch(requestUpdateUser());
      const response = await userService.submitQuestions(
        answers,
        isDupeSSNFlag,
      );
      const { userInfo } = await dispatch(getFreshUserInfo());
      if (response && response.items) {
        throw new PartialQuestionsError(response);
      }
      const { isUserResurrected, phoneNumber } = response;
      const showDupKBAVerifyPhone = getState().signup.dupKBAPhoneVerification;
      if (
        userInfo.status === 'pending' &&
        !showDupKBAVerifyPhone &&
        !phoneNumber &&
        !direct2Premium
      ) {
        dispatch(handleErrors('SU_1021', 'Failure to submit questions'));
      } else {
        dispatch({ type: userConstants.GET_SUCCESS, userInfo });

        if (isDupeSSNFlag) {
          dispatch(
            handleDupSSNSuccessQuestions(
              phoneNumber,
              isUserResurrected,
              history,
              userInfo,
              extraProps,
            ),
          );
          await dispatch(
            updatePasswordOneClickSignup({
              password: state?.signup?.ssn?.password,
            }),
          );
        } else {
          await dispatch(
            postSignUpHandler(
              history,
              userInfo,
              'Signup KBAs',
              null,
              extraProps,
            ),
          );
        }
      }
    } catch (error) {
      // if status 200 but we have new set of questions
      // dispatch action to update redux store with new questions
      // else then handle questions failures
      logger.reportAPIError(error, 'submitKBAQuestions');
      logger.errorException(
        `Error during Submit KBA Questions sequence`,
        error,
      );

      if (isDupeSSNFlag && !error.data) {
        const isSSNExist = JSON.parse(getItem(ssnExistsData));
        setUserLogoutTime(null); //clear session cookie for locked account
        dispatch(
          signupActions.signupAlert(
            'You have too many failed attempts. Please try another method.',
          ),
        );
        dispatch(dupeSSN(isSSNExist));
      } else {
        if (error.data) {
          dispatch({
            type: signupConstants.GET_QUESTIONS_SUCCESS,
            questions: error.data,
          });
        }
        if (
          error.errorCode !== 'SU_1043' &&
          error.errorCode !== 'SU_1040' &&
          error.errorCode !== 'CP_1013'
        ) {
          dispatch(failureSubmitQuestions(error));
        }
        dispatch(handleErrors(error.errorCode, error.error?.toString()));
      }
    }
  };

const finalizeSignupAction = () => {
  return { type: signupConstants.FINALIZE_SIGNUP };
};
const updateStep = (action, step) => {
  return dispatch => {
    dispatch({ type: signupConstants.UPDATE_SIGNUP_STEP, action, step });
  };
};

const addCustomAlertCategory = props => {
  const finalProps = { ...props };
  finalProps.Categories = CATEGORY_CUSTOM_ALERTS;
  return finalProps;
};

const reportPrimaryAddressError = (err, dispatch) => {
  const attrs = addCustomAlertCategory(err);
  dispatch(
    standardEventHandler(mixpanelEvents.SIGNUP_PRIMARY_ADDRESS_FAILED, attrs),
  );
};

const updateUserAddress = address => async (dispatch, getState) => {
  const validationType = getState().signup.addressValidated
    ? USER_VALIDATION
    : SMARTY_STREETS_VALIDATION;
  logger.debug(`Start ${validationType} address validation sequence`);

  try {
    dispatch(requestUpdateUser());
    await userService.updateUserAddress(address, validationType);
    await dispatch(getFreshUserInfo());
    dispatch(updateStep('GOTO', PHONE_NUMBER));
    dispatch(success());
  } catch (error) {
    if (error.errorCode === 'SU_1066') {
      dispatch(smartyStreetsFuzzyMatch(error));
    } else {
      let addressValidationFailure =
        error.errorCode && error.errorCode === 'SU_1017';
      dispatch(signupAlert(error.error.toString()));
      dispatch(addressFailure(error, addressValidationFailure));
    }
    logger.reportAPIError(error, 'updateUserAddress');
    logger.errorException(
      `Error during ${validationType} address validation sequence`,
      error,
    );
    reportPrimaryAddressError(error, dispatch);
  }
};

const updateUserAddressTUError = address => async (dispatch, getState) => {
  const validationType = USER_VALIDATION;
  logger.debug(`Start ${validationType} address validation sequence`);

  try {
    await userService.updateUserAddress(address, validationType);
    await dispatch(getFreshUserInfo());
  } catch (error) {
    if (error.errorCode === 'SU_1066') {
      dispatch(smartyStreetsFuzzyMatch(error));
    } else {
      let addressValidationFailure =
        error.errorCode && error.errorCode === 'SU_1017';
      dispatch(signupAlert(error.error.toString()));
      dispatch(addressFailure(error, addressValidationFailure));
    }
    logger.reportAPIError(error, 'updateUserAddress');
    logger.errorException(
      `Error during ${validationType} address validation sequence`,
      error,
    );
    reportPrimaryAddressError(error, dispatch);
    throw error;
  }
};

const success = () => {
  return { type: signupConstants.UPDATE_USER_ADDRESS_SUCCESS };
};

const addressFailure = (error, addressValidated) => {
  return {
    type: signupConstants.UPDATE_USER_ADDRESS_FAIL,
    error,
    addressValidated: addressValidated,
  };
};

const smartyStreetsFuzzyMatch = error => {
  return { type: signupConstants.ADDRESS_FUZZY_MATCH, error };
};

const addressFormActionSummary =
  (user, address, ssn) => async (dispatch, getState) => {
    dispatch(request({ user, address }));
    if (getState().signup.ssnRetriesCount <= 2) {
      try {
        await Promise.all([
          patchUser(user),
          userService.updateUserAddress(address, USER_VALIDATION),
          ssn ? userService.updateUserSSN(ssn) : null,
        ]);
        await dispatch(getFreshUserInfo());
        dispatch(getQuestions('all'));
      } catch (error) {
        if (getState().signup.ssnRetriesCount === 2) {
          dispatch(errorLogout(QUESTIONS_NOT_RECEIVED));
        } else {
          dispatch(failure(error));
          dispatch(signupAlert(error.error.toString()));
        }
      }
    } else {
      dispatch(errorLogout(QUESTIONS_NOT_RECEIVED));
    }

    function request(details) {
      return { type: signupConstants.SUMMARY_UPDATE_REQUEST, details };
    }

    function failure(error) {
      return { type: signupConstants.UPDATE_USER_FAIL, error };
    }
  };

const signupAlert = message => {
  return { type: signupConstants.SIGNUP_CREATE_ALERT, message: message };
};

const signupAlertClear = () => {
  return { type: signupConstants.SIGNUP_CLEAR_ALERT };
};

export const errorLogout = (errorPage, errorMessage) => dispatch => {
  dispatch({
    type: signupConstants.SIGNUP_ERROR_LOGOUT,
    errorPage: errorPage,
    errorMessage: errorMessage,
  });
};

export const handleErrors =
  (errorCode, errorMessage, comeFromTUEnroll = false) =>
  (dispatch, getState) => {
    window.scroll(0, 0);

    const { selfLoanForTUErrors, ssnRequire9 } = getState().signup;
    if (selfLoanForTUErrors.indexOf(errorCode) !== -1) {
      dispatch(
        updateSelfLoanForTUErrorsConfig({
          comeFromTUEnroll: comeFromTUEnroll ? 'yes' : 'no',
          lastSsnRequire9: ssnRequire9,
        }),
      );
      dispatch(updateStep('GOTO', SELF_LOAN_TU_ENROLL));
      return;
    }

    const isSSNExist = !!getItem(ssnExistsData);

    switch (errorCode) {
      case 'SU_1043':
        //Your account has been locked for 30 days due to incorrect answers or too many failed sign up attempts.
        visitorReportError('Account Lockout', errorCode, logger);
        dispatch(errorLogout(ACCOUNT_LOCKOUT, errorMessage));
        break;
      case 'SU_1040': //There are some errors during pulling the user credit profile.
      case 'CP_1013': // error pulling credit profile - deceased user
        visitorReportError('Credit Profile Error', errorCode, logger);
        dispatch(errorLogout(CREDIT_PROFILE_ERR));
        break;
      case 'SU_1020': //There is no questions for this user.
      case 'SU_1024': //There are some errors during pulling the questions.
      case 'UN_1016': //Something went wrong
      case 'BU_TU_AU_801.18.1': //Duplicate error
      case 'BU_TU_AU_801.18.2': //No data available
      case 'BU_TU_AU_801.18.3': //Fail Verification
      case 'BU_TU_AU_801.18.4': //Suppressed record
      case 'BU_TU_800.1': //Service failure
      case 'INV_1003':
        if (isInvisibleUserError(errorCode)) {
          handleInvisibleUser(dispatch, errorCode, errorMessage);
        } else {
          if (isSSNExist) {
            dispatch(errorLogout(GENERAL));
          } else {
            dispatch(updateStep('GOTO', SUMMARY));
            if (errorCode === 'BU_TU_AU_801.18.4') {
              //Suppressed record
              dispatch(
                signupAlert(
                  '<b>We are unable to proceed with your request, please verify your information and try again!</b>',
                ),
              );
            }
          }
        }
        break;
      case 'BU_TU_E_801.21': // Invalid Email validation from TransUnion
        visitorReportError(
          'Email contains unrecognized characters',
          null,
          logger,
        );
        dispatch(updateStep('GOTO', REVALIDATE_EMAIL));
        dispatch(successUpdateUser());
        break;
      case 'questionsNotReceived':
        visitorReportError('Questions Not Received Failure', null, logger);
        dispatch(errorLogout(QUESTIONS_NOT_RECEIVED));
        break;
      case 'SU_1021':
      case 'SU_1022':
        //The questions are partially correct. Please resubmit questions.
        //The questions are incorrect. Please resubmit questions.
        dispatch(
          signupAlert(
            '<b>Our credit providers did not accept one or more of your responses to the identity verification questions.</b> Please read the questions carefully and try again.',
          ),
        );
        break;
      case 'SU_1068':
        //TU TrustEV Failure
        visitorReportError('TU TrustEV Failure', errorCode, logger);
        dispatch(errorLogout(GENERAL_TU));
        break;
      case 'SU_1025':
        //There are some errors during sending the answers.
        visitorReportError('Error Sending KBA Answers', errorCode, logger);
        dispatch(errorLogout(GENERAL));
        break;
      case 'BU_TU_E_801.7': //General failure
        visitorReportError('TU Failure', errorCode, logger);
        dispatch(errorLogout(GENERAL));
        break;
      default:
        visitorReportError('Unknown', errorCode, logger);
        dispatch(errorLogout(GENERAL));
        break;
    }
  };

const getUserAgentString = () => {
  let parser = new UAParser.UAParser();
  let browser = parser.getBrowser().name;

  if (browser === 'Chrome') {
    if (navigator.userAgent.includes('CriOS')) {
      browser = 'iOS Chrome';
    } else if (navigator.userAgent.includes('Mobile Safari')) {
      browser = 'Android Chrome';
    } else {
      browser = 'Chrome';
    }
  }

  return {
    type: signupConstants.GET_USERAGENT_STRING,
    ua: browser,
  };
};

const isInvisibleUserError = errorCode => {
  return (
    errorCode === 'BU_TU_AU_801.18.1' ||
    errorCode === 'BU_TU_AU_801.18.2' ||
    errorCode === 'BU_TU_AU_801.18.3' ||
    errorCode === 'INV_1003'
  );
};

const handleInvisibleUser = async (dispatch, errorCode, errorMessage) => {
  if (getOneClickSignupTracking()) {
    dispatch(standardEventHandler(ocsFlowTracking[INVISIBLE_FLOW]));
  }
  let invisibleUserData = {
    errorCode: errorCode,
    errorDescription: errorMessage,
  };

  const userInfo = await dispatch(getUserInfo(true));

  const bucketTest = optimizelyClient.decide('invisible_user');

  if (
    bucketTest.variables.perform_ssn_verification === true ||
    isInvisibleUser(userInfo)
  ) {
    if (userInfo?.signUpStatus === SSN_VERIFICATION_FAILED) {
      dispatch(errorLogout(GENERAL));
    } else if (isInvisibleUser(userInfo)) {
      dispatch(updateStep('GOTO', BOOSTER_SUCCESS_LANDING));
    } else {
      try {
        await dispatch(verifyInvisibleSSN());
        await dispatch(getUserInfo());
        triggerClientPixelForInvisibleRegistration(userInfo);
        dispatch(updateStep('GOTO', BOOSTER_SUCCESS_LANDING));
      } catch (err) {
        logger.reportAPIError(err);
        await dispatch(getUserInfo());
        dispatch(errorLogout(GENERAL));
      }
    }
  } else {
    dispatch(updateStep('GOTO', BOOSTER_ENROLL));
    dispatch({
      type: signupConstants.INVISIBLE_USER,
      loading: false,
      invisibleUserData,
    });
  }
};

const handleDupKBAVerifyPhoneExp =
  questionsResponse => async (dispatch, getState) => {
    if (questionsResponse.isOtpEligible) {
      return questionsResponse;
    }

    manuallyActivatePage('mrph_dup_ssn_kba_page');
    try {
      const variation = await pollForExp(SIGNUP_DUP_SSN_KBA_VERIFY_PHONE);
      const isExpActive = variation === 'VAR1';
      dispatch({
        type: signupConstants.DUP_KBA_PHONE_VERIFICATION,
        data: {
          dupKBAPhoneVerification: isExpActive,
        },
      });
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return questionsResponse;
    }
  };

const handleDupSSNSuccessQuestions =
  (phoneNumber, isUserResurrected, history, userInfo, extraProps) =>
  (dispatch, getState) => {
    const showDupKBAVerifyPhone = getState().signup.dupKBAPhoneVerification;
    if (showDupKBAVerifyPhone) {
      const phoneVerificationData = {
        phoneNumber,
        resendAttempts: 2,
        retryAttempts: 3,
      };
      setItem('dupKBAPhoneVerification', JSON.stringify(phoneVerificationData));
      dispatch(updateStep('GOTO', DUP_KBA_PHONE_VERIFICATION));
      dispatch({
        type: signupConstants.DUP_KBA_PHONE_VERIFICATION,
        data: {
          loading: false,
        },
      });
    } else {
      reportOptimizely('dup_ssn_sign_up', 'event');
      setItem('dupeSSNsignup', true);
      if (isUserResurrected) {
        triggerResurrectedUser(userInfo);
      }
      setResurrectedUser(isUserResurrected);
      dispatch(
        postSignUpHandler(
          history,
          userInfo,
          'Signup KBAs - Morpheus',
          isUserResurrected,
          extraProps,
        ),
      );
    }
  };

const setSignupStep = userInfo => async (dispatch, getState) => {
  const pending = userInfo.pendingReasons[0];
  const isSSNExist = JSON.parse(getItem(ssnExistsData));
  const passwordStepCacheState = getItem('passwordStep')
    ? JSON.parse(getItem('passwordStep'))
    : {};
  const dupKBAVerifyPhoneCacheState = getItem('dupKBAPhoneVerification');
  const isOTPValidation = JSON.parse(getItem(otpValidation));
  removeItem(otpValidation);

  if (passwordStepCacheState.showStep) {
    dispatch(setStep('password'));
  } else if (dupKBAVerifyPhoneCacheState) {
    dispatch(setStep('dupKBAPhoneVerification'));
  } else if (
    pending === QUESTIONS ||
    pending === QUESTIONS_SSN_MATCH ||
    pending === VALIDATION
  ) {
    if (isSSNExist) {
      if (isSSNExist.fundedUser || isSSNExist.hasLinkedAccounts) {
        dispatch(dupeSSN(isSSNExist));
      } else {
        dispatch(getDupSSNQuestions(isSSNExist));
      }
    } else if (isOTPValidation) {
      setItem(otpValidation, true);
      await dispatch(getQuestions('all'));
      dispatch(setStep('questions'));
      dispatch(setShowOTP({ showOTP: true }));
    } else {
      const questionsRes = await dispatch(getQuestions('all'));
      const isOtpEligible = questionsRes?.isOtpEligible;
      if (isOtpEligible === false) {
        await dispatch(setShowOTP({ showOTP: true }));
      }
    }
  } else if (pending === SSN && userInfo.contactPhoneNumber === null) {
    dispatch(setStep(PHONE_NUMBER));
  } else {
    dispatch(setStep(pending));
  }

  function setStep(pending) {
    return { type: signupConstants.SET_SIGNUP_STEP, pending };
  }
};

export const requestVerifyUserSSN = () => {
  return { type: signupConstants.VERIFY_USER_SSN_REQUEST };
};

export const successVerifyUserSSN = () => {
  return { type: signupConstants.VERIFY_USER_SSN_SUCCESS };
};

export const failureVerifyUserSSN = error => {
  return { type: signupConstants.VERIFY_USER_SSN_FAIL, error };
};

export const clearErrorAlerts = () => {
  return { type: signupConstants.CLEAR_ERROR_ALERTS };
};

export const verifyInvisibleSSN = () => async (dispatch, getState) => {
  const state = getState();
  const userInfo = state?.userInfo?.userInfo;
  if (
    userInfo?.signUpStatus === NOT_FOUND_IN_BUREAU ||
    userInfo?.signUpStatus === BUREAU_GET_QS_FAILED
  ) {
    dispatch(requestVerifyUserSSN());
    try {
      const data = verifyUserSSN({});
      dispatch(successVerifyUserSSN());
      return data;
    } catch (err) {
      dispatch(failureVerifyUserSSN(err));
      throw err;
    }
  }
};

export const updateSelfLoanForTUErrorsConfig = (selfLoanData = {}) => {
  return dispatch => {
    dispatch({
      type: signupConstants.UPDATE_SELF_LOAN_ERRORS,
      selfLoanData,
    });
  };
};

export const setVariationsByOneClick = expOneClickSignup => {
  return dispatch => {
    dispatch({
      type: signupConstants.SET_VARIATIONS,
      expOneClickSignup,
    });
  };
};

export const setTryLoginWelcomeBack = tryLoginWelcomeBack => {
  return dispatch => {
    dispatch({
      type: signupConstants.SIGNUP_TRY_LOGIN_ON_WELCOME_BACK,
      tryLoginWelcomeBack,
    });
  };
};

export const signupActions = {
  createUser,
  createUserForTUErr,
  updateProfile,
  updatePhoneNumber,
  updateUserAddress,
  updateUserAddressPatch,
  updateUserAddressTUError,
  updateUserSSN,
  revalidateEmail,
  getDupSSNQuestions,
  updatePassword,
  getQuestions,
  submitKBAQuestions,
  updateStep,
  signupAlert,
  signupAlertClear,
  addressFormActionSummary,
  getUserAgentString,
  errorLogout,
  setSignupStep,
  storeTuSessionId,
  setShowOTP,
  setShowKBA,
  updateSelfLoanForTUErrorsConfig,
};

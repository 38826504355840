export const RESET_ACCOUNT_VERIFICATION = 'RESET_ACCOUNT_VERIFICATION';
export const SET_ACCOUNT_VERIFICATION_DATA = 'SET_ACCOUNT_VERIFICATION_DATA';
export const ACCOUNT_VERIFICATION_OTP_ERROR = 'ACCOUNT_VERIFICATION_OTP_ERROR';
export const SET_ACCOUNT_VERIFICATION_STEP = 'SET_ACCOUNT_VERIFICATION_STEP';
export const ACCOUNT_VERIFICATION_DUPLICATED_EMAIL =
  'ACCOUNT_VERIFICATION_DUPLICATED_EMAIL';
export const ACCOUNT_VERIFICATION_DUPLICATED_PHONE =
  'ACCOUNT_VERIFICATION_DUPLICATED_PHONE';
export const DEFAULT_LOADING_DESCRIPTION = 'Loading...';
export const CREATE_PASSWORD_LOADING_DESCRIPTION =
  'Your account has been successfully updated';

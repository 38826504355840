/* Event names used for mixpanel */

import {
  CREDIT_BUILDER_ENROLL_COMPLETE,
  MIGRATION_CONFIRM_FREE,
  MIGRATION_CONFIRM_PREMIUM,
  SESAME_CASH_ENROLL,
} from 'externals/_tracking/types/eventTypes.constants';

export const mixpanelEvents = {
  CLICK: 'Click',
  CLICK_APPLY: 'Click Apply',
  COMPLETED: 'Completed',
  INTERNAL_EMAIL_REDIRECT: 'Internal Email Redirect',
  LOGIN: 'Logged In',
  SEEN_OFFER: 'Seen Offer',
  SEEN_ACTIONS: 'Seen Actions',
  VIEW_ACTIONS: 'View Actions',
  VIEW_ALERT: 'View Alert',
  VIEW_MODAL: 'View Pop Up',
  VIEW_MODULE: 'View Module',
  SEEN_MODULE: 'Seen Module',
  VIEW_OFFER: 'View Offer',
  VIEW_PAGE: 'View Page',
  VIEW_POPUP: 'View Pop Up',
  DISMISS_POPUP: 'Dismiss Pop Up',
  DISMISS_MODAL: 'Dismiss Pop Up',
  LOGOUT: 'Logged out',
  ERROR_API: 'API Error',
  DATA_ERROR: 'Data Error',
  APP_CLIENT_ERROR: 'App Client Error',
  FATAL_CLIENT_ERROR: 'Fatal Client Error',
  CLICK_PREQUAL: 'Click',
  SIGNUP_ACCOUNT_CREATED: 'Signup Account Created',
  REGISTER: 'Signup Complete',
  SIGNUP_FINISH: 'Signup Finish',
  SEEN_NOTIFICATION: 'Seen Notification',
  //PIXEL_FIRED: 'Pixel Fired',
  [SESAME_CASH_ENROLL]: 'Sesame Cash Enroll Complete',
  [CREDIT_BUILDER_ENROLL_COMPLETE]: 'Credit Builder Enroll Complete',
  SUBSCRIPTION_PURCHASE_RESULT: 'Subscription Purchase Result',
  LOGIN_FAILED: 'Login Failed',
  SIGNUP_PRIMARY_ADDRESS_FAILED: 'SLAPI Primary Address Failed Signup',
  TU_PROFILE_REFRESH_FAILED: 'TU Profile Refresh Failed',
  HOSTED_APP_SUBMISSION: 'Hosted App Submission',
  [MIGRATION_CONFIRM_FREE]: 'Migration Confirm-Free',
  [MIGRATION_CONFIRM_PREMIUM]: 'Migration Confirm-Premium',
};

/* 
  Event properties for mixpanel events from:
  https://docs.google.com/spreadsheets/d/1FiENuHOhc3dqlk9JvVAZAh6DTeIuN-e6lceTMvEQrKw/edit?pli=1#gid=1940569470
 */
export const mixpanelAdditionalProps = {
  CLICK: [
    'Page Position',
    'Offer Module Position',
    'Click Type',
    'Page Name',
    'Bank Name',
  ],
  CLICK_APPLY: [
    'Click ID',
    'Client IP',
    'CreditReportId',
    'CreditScoreId',
    'Customer Type',
    'Last Login',
    'Last Refresh Date',
    'Logged In',
    'Pop Up Name',
    'Module Position',
    'Offer Module Position',
    'Offer Position',
    'Page Form Factor',
    'Page Name',
    'Page Position',
    'Previous Page',
    'Product Key',
    'Product Offer',
    'RPC Ranking',
    'State of Residence',
    'Vertical',
    'VisitorSessionID',
    'distinct_id',
    'Review Rating',
    'Review Count',
  ],
  DISMISS_MODAL: ['Page Name', 'Offer Module Position', 'Page Position'],
  VIEW_MODAL: ['Page Name', 'Offer Module Position', 'Page Position'],
  INTERNAL_EMAIL_REDIRECT: ['Offer Module Position', 'Page Position'],
  LOGOUT: ['Ref Code'],
  SEEN_OFFER: ['Page Name', 'Offer Module Position', 'Page Position'],
  SEEN_ACTIONS: [
    'Page Name',
    'Action Position',
    'Page Position',
    'Action Name',
  ],
  VIEW_ACTIONS: [
    'Page Name',
    'Action Position',
    'Page Position',
    'Action Name',
  ],
  VIEW_ALERT: ['Page Name', 'Offer Module Position', 'Page Position'],
  VIEW_POP_UP: [
    'Page Name',
    'Pop Up Name',
    'Offer Module Position',
    'Page Position',
  ],
  VIEW_MODULE: ['Page Name', 'Offer Module Position', 'Page Position'],
  SEEN_MODULE: ['Page Name', 'Offer Module Position', 'Page Position'],
  SEEN_NOTIFICATION: ['Page Name', 'Offer Module Position', 'Page Position'],
  VIEW_OFFER: [
    'Page Name',
    'Pop Up Name',
    'Page Position',
    'Offer Module Position',
    'Module Position',
    'Page Form Factor',
  ],
  VIEW_POPUP: ['Page Name', 'Offer Module Position', 'Page Position'],
  VIEW_PAGE: ['Page Name'],
  LOGIN: ['Ref Code', 'Page Name', 'Profile Refresh', 'Score Refresh'],
  CLICK_PREQUAL: ['Page Name', 'Product Key', 'Page Position'],
  SIGNUP_ACCOUNT_CREATED: ['Page Name'],
  COMPLETED: ['Page Name'],
  REGISTER: ['Page Name'],
  SIGNUP_FINISH: ['Page Name'],
  SUBSCRIPTION_PURCHASE_RESULT: [
    'Purchase Result',
    'Failure reason',
    'Plan Type',
    'Page Name',
  ],
  DISMISS_POPUP: ['Page Name'],
  HOSTED_APP_SUBMISSION: [
    'Click ID',
    'Client IP',
    'CreditReportId',
    'CreditScoreId',
    'Customer Type',
    'Last Login',
    'Last Refresh Date',
    'Logged In',
    'Pop Up Name',
    'Module Position',
    'Offer Module Position',
    'Offer Position',
    'Page Form Factor',
    'Page Name',
    'Page Position',
    'Previous Page',
    'Product Key',
    'Product Offer',
    'RPC Ranking',
    'State of Residence',
    'Vertical',
    'VisitorSessionID',
    'distinct_id',
    'Review Rating',
    'Review Count',
  ],
};

import React from 'react';
import './Label.scss';
import classnames from 'classnames';

function Label({
  type,
  bold,
  color,
  inline,
  children,
  className,
  datatestid,
  prefix,
  tabIndex = '0',
  typeHeader = 'div',
  role = 'definition',
  ariaHidden = false,
  ...props
}) {
  const labelClass = classnames({
    [type]: type,
    [color]: color,
    bold: bold,
    inlineBlock: inline,
    notBold: bold !== undefined && !bold,
    [className]: className,
  });

  const Element = typeHeader;

  const handleEnterKeyPress = event => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (typeof props?.onClick === 'function') {
        props?.onClick(event);
      }
    }
  };

  return (
    <Element
      className={labelClass}
      data-testid={datatestid ? `${datatestid}` : null}
      tabIndex={tabIndex}
      role={ariaHidden ? null : role}
      aria-hidden={ariaHidden}
      onKeyDown={handleEnterKeyPress}
      {...props}
    >
      {children}
    </Element>
  );
}

export default Label;

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './Link.scss';

/* Need to wrap all the links for track event handler */
function MrphLink({
  children,
  onClickCallback,
  to = '#',
  datatestid,
  className,
  isInline,
  linkClassName,
  isDisplayNone,
  ariaLabel = 'Link',
  title = '',
  target,
  rel,
  tabIndexLink = '0',
  disableAriaLabel = false,
  ariaLabelledby,
  ...extraProps
}) {
  const linkRef = useRef(null);

  const handleKeyPress = e => {
    if (e.key === 'Enter' && linkRef.current) {
      linkRef.current.click();
    }
  };

  const internalOnClick = e => {
    if (onClickCallback) onClickCallback(extraProps);
  };

  const linkClasses = classnames(
    'MrphLink router-link secondary_text_light',
    linkClassName,
  );

  const displayClass = classnames({
    inline: isInline,
    'inline-block': !isInline && !isDisplayNone,
    none: isDisplayNone,
  });

  const wrapperLinkClass = classnames('wrapperLink', className);

  const style = {
    display: displayClass,
  };

  return (
    <div
      data-testid={datatestid}
      onClick={internalOnClick}
      onKeyDown={e => handleKeyPress(e)}
      className={wrapperLinkClass}
      style={style}
      // role="link"
      // aria-label={ariaLabel}
    >
      <Link
        to={to}
        className={linkClasses}
        replace={extraProps.replace}
        tabIndex={tabIndexLink}
        ref={linkRef}
        {...(!disableAriaLabel && { 'aria-label': ariaLabel })}
        title={title}
        target={target}
        rel={rel}
        aria-labelledby={ariaLabelledby}
      >
        {children}
      </Link>
    </div>
  );
}

MrphLink.defaultProps = {
  isInline: false,
};

export { MrphLink as Link };

import {
  buildOCSAuthPhone,
  buildOCSGetIdentityURL,
  buildOCSPrefillFinish,
  buildOCSPrefillStart,
  buildOCSVerifyFingerprint,
  buildOCSPrefillVerifyOtp,
  buildOCSPrefillAuthStatus,
  buildOCSGetIdentityLTE,
  buildOCSEligibility,
  buildOCSContinueAuth,
  buildverifyOTPDeviceVerification,
  buildCompleteFreeAccountRegistration,
} from 'api-urls';
import { getMarketingCookies } from 'helpers/marketing.helper';
import {
  getIdentityIPHeader,
  getIPHeader,
} from 'helpers/oneClickSignup.helpers';
import {
  buildFetchOptionsWithHeaders,
  formatToHttps,
  standardizedFetchHandler,
  convertObjectToQueryString,
} from './service_helpers';
import { getIovationBlackbox } from 'helpers/common';
import { goToPath } from 'helpers/domHelper';
import { shouldRedirectToSignUpError } from 'helpers/env';

//TODO: update once slapi apis are ready.
export async function createUser(formData, isPrefilledPhone) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (isPrefilledPhone) {
        reject();
      } else {
        resolve();
      }
    }, 2000);
  });
}

export async function prefillStart(body, isWifi) {
  const customHeader = isWifi ? {} : getIPHeader();
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'POST',
      body,
    },
    customHeader,
  );

  return withSignUpErrorHandling(
    standardizedFetchHandler(buildOCSPrefillStart(), requestOptions),
  );
}

export async function validateRedirectUrl(redirectUrl) {
  const finalRedirectUrl = redirectUrl
    ? formatToHttps(`${redirectUrl}&r=f`)
    : ''; //extra parameter to make this a GET call
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'GET',
      credentials: 'same-origin',
    },
    null,
    true,
  );

  return standardizedFetchHandler(finalRedirectUrl, requestOptions);
}

export async function verifyFingerprint(params) {
  const customHeader = getIPHeader();
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'POST',
      body: params,
    },
    customHeader,
  );

  return withSignUpErrorHandling(
    standardizedFetchHandler(buildOCSVerifyFingerprint(), requestOptions),
  );
}

export async function continueAuth(queryOptions) {
  const customHeader = getIPHeader();
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'GET',
    },
    customHeader,
  );
  const queryString = convertObjectToQueryString(queryOptions);

  return withSignUpErrorHandling(
    standardizedFetchHandler(
      buildOCSContinueAuth() + queryString,
      requestOptions,
    ),
  );
}

export async function authenticatePhone(formData) {
  const customHeader = getIPHeader();
  const affiliateTracking = getMarketingCookies();
  const blackbox = getIovationBlackbox();
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'POST',
      body: Object.assign({}, formData, {
        accountMarketingAttribution: affiliateTracking,
        blackbox,
      }),
    },
    customHeader,
  );

  return withSignUpErrorHandling(
    standardizedFetchHandler(buildOCSAuthPhone(), requestOptions),
  );
}

export async function getIdentity(formData) {
  const customHeader = getIdentityIPHeader();
  const blackbox = getIovationBlackbox();

  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'POST',
      body: Object.assign({}, formData, {
        blackbox,
      }),
    },
    customHeader,
  );

  return withSignUpErrorHandling(
    standardizedFetchHandler(buildOCSGetIdentityURL(), requestOptions),
  );
}

export async function getIdentityLTE(formData) {
  const customHeader = getIdentityIPHeader();
  const affiliateTracking = getMarketingCookies();
  const blackbox = getIovationBlackbox();
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'POST',
      body: Object.assign({}, formData, {
        accountMarketingAttribution: affiliateTracking,
        blackbox,
      }),
    },
    customHeader,
  );

  return withSignUpErrorHandling(
    standardizedFetchHandler(buildOCSGetIdentityLTE(), requestOptions),
  );
}

export async function prefillFinish(formData) {
  const customHeader = getIPHeader();
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'POST',
      body: formData,
    },
    customHeader,
  );

  return withSignUpErrorHandling(
    standardizedFetchHandler(buildOCSPrefillFinish(), requestOptions),
  );
}

export async function verifyOtp(body) {
  const customHeader = getIPHeader();
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'POST',
      body,
    },
    customHeader,
  );

  return withSignUpErrorHandling(
    standardizedFetchHandler(buildOCSPrefillVerifyOtp(), requestOptions),
  );
}

export const verifyOTPDeviceVerification = async passCode => {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: {
      passcode: passCode,
    },
  });

  return withSignUpErrorHandling(
    standardizedFetchHandler(
      buildverifyOTPDeviceVerification(),
      requestOptions,
    ),
  );
};

export async function validateAuthStatus() {
  const customHeader = getIPHeader();
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'GET',
    },
    customHeader,
  );

  return withSignUpErrorHandling(
    standardizedFetchHandler(buildOCSPrefillAuthStatus(), requestOptions),
  );
}

export async function checkPrefillEligibility(body) {
  const customHeader = getIPHeader();
  const requestOptions = buildFetchOptionsWithHeaders(
    {
      method: 'POST',
      body,
    },
    customHeader,
  );

  return standardizedFetchHandler(buildOCSEligibility(), requestOptions);
}

export async function completeFreeAccountRegistration(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });

  return withSignUpErrorHandling(
    standardizedFetchHandler(
      buildCompleteFreeAccountRegistration(),
      requestOptions,
    ),
  );
}

export async function withSignUpErrorHandling(promise) {
  try {
    const response = await promise;
    return response;
  } catch (error) {
    const errorCode = error?.code || '';
    if (
      (['TFA_1008', 'EN2FA_1008', 'DDV_1007', 'BU_TU_E_801.7'].includes(
        errorCode,
      ) ||
        errorCode?.startsWith('DV_')) &&
      shouldRedirectToSignUpError()
    ) {
      redirectUserToSignupErrorPage();
      return;
    }
    throw error;
  }
}

export const redirectUserToSignupErrorPage = () => {
  goToPath('/signupError?type=GENERAL');
};

import * as UAParser from 'ua-parser-js';

export const getUserAgent = () => window.navigator.userAgent;

export const isMobileByOS = () => {
  const ua = new UAParser.UAParser(getUserAgent());

  if (!ua || !ua.getOS()) {
    return false;
  }

  const name = ua.getOS().name;

  if (name === null) {
    return false;
  }

  if (/android/i.test(name)) {
    return true;
  }

  if (/ios/i.test(name)) {
    return true;
  }

  return false;
};

export const isAndroidByOS = () => {
  if (!isMobileByOS()) {
    return false;
  }
  const ua = new UAParser.UAParser(getUserAgent());
  if (/android/i.test(ua.getOS().name)) {
    return true;
  }
  return false;
};

export const isIOSByOS = () => {
  if (!isMobileByOS()) {
    return false;
  }
  const ua = new UAParser.UAParser(getUserAgent());
  return /ios/i.test(ua.getOS().name);
};

export const getBrowser = () => {
  const ua = new UAParser.UAParser(getUserAgent());
  return ua.getBrowser();
};

export const getDeviceOs = () => {
  const ua = new UAParser.UAParser(getUserAgent());
  return ua.getOS().name;
};

export const getDeviceModel = () => {
  const ua = new UAParser.UAParser(getUserAgent());
  return ua.getDevice().model;
};

export const isMobileTabletByWidth = () => {
  return window.innerWidth < 1095;
};

export const isMobileByWidth = () => {
  return window.innerWidth < 600;
};

export const areCookiesEnabled = () => {
  return navigator.cookieEnabled;
};

export const isItSafariBrowser = () => getBrowser().name.includes('Safari');

export const isIosSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};

export const isIosFirefox = () => {
  const userAgent = getUserAgent();
  const iOS = /iPad|iPhone/i.test(userAgent);
  return iOS && /FxiOS/i.test(userAgent);
};

export const TIPS = 'Tips';
export const SUGGESTED_OFFERS = 'Suggested Offers';
export const SUGGESTED_PERSONAL_LOAN_OFFERS = 'Suggested Personal Loan Offers';
export const SUGGESTED_AUTO_LOAN_OFFERS = 'Overview Auto Refinance';
export const SESAME_PLUS_CARDS_OFFERS = 'Sesame Plus Cards Module';
export const AUTO_PURCHASE_LOAN_MODULE = 'Overview Auto Purchase';
export const MARKETPLACE_SUGGESTED_OFFERS = 'Suggested Best Offers';
export const MARKETPLACE_BT_OFFERS = 'Balance Transfer Offers';
export const MARKETPLACE_REWARDS_OFFERS = 'Reward Offers';
export const MARKETPLACE_SECURED_OFFERS = 'Secured Offers';
export const MARKETPLACE_BUSINESS_OFFERS = 'Business Offers';
export const MARKETPLACE_BUSINESS_LOAN = 'Business Loan';
export const MARKETPLACE_NO_ANNUAL_FEE_OFFERS = 'No Annual Fee Offers';
export const MARKETPLACE_TRAVEL_REWARDS_OFFERS = 'Travel Offers';
export const CASH_BACK_ON_MARKETPLACE = 'Cash Back Offers';
export const OVERVIEW_IMPACT = 'Credit Score Dial';
export const CREDIT_REPORT = 'Credit Report';
export const CREDIT_ANALYSIS = 'Credit Analysis';
export const SESAME_POTENTIAL = 'Credit Score Dial with Sesame Potential';
export const DEBT_ANALYSIS = 'Debt Analysis';
export const SUGGESTED_ACTIONS = 'Suggested Actions';
export const PERSONAL_LOAN_PREQUAL_HIGH_CC_DEBT = 'PL Prequal High Cc Debt';
export const PERSONAL_LOAN_PREQUAL_HIGH_CC_DEBT_OFFER =
  'PL Prequal High Cc Debt Offer';
export const PERSONAL_LOAN_PREQUAL = 'PL Prequal';
export const PL_PREQUAL_OFFER_MODULE = 'PL Prequal Offer';
export const PL_PREQUAL_OFFER_MODULE_OFFER = 'PL Prequal Offer';
export const PL_PREQUAL_FORM_MODULE = 'PL Prequal Form';
export const PL_HIGH_SCORE = 'PL High Score';
export const PL_HIGH_SCORE_OFFER = 'PL High Score Offer';
export const PL_LOW_SCORE = 'PL Low Score';
export const PL_LOW_SCORE_OFFER = 'PL Low Score Offer';
export const PL_SEE_ALL_OFFERS = 'See All';
export const DASHBOARD_SCORE_RANGE_MODAL = 'Dashboard Score Range Modal';
export const TERMINATE_PREMIUM_USER_MODAL = 'Terminate Premium User';
export const NO_PREQUAL_CAPITAL_ONE = 'No Prequal Capital One';
export const PREQUAL_CAPITAL_ONE = 'Prequal Capital One';
export const NEW_PREQUAL_PERSONAL_LOAN = 'Personal Loan';
export const PREQUAL_PERSONAL_LOAN = 'Prequal Personal Loan';
export const PREQUAL_PERSONAL_LOAN_FORM = 'Prequal Personal Loan Form';
export const NO_PREQUAL_PERSONAL_LOAN = 'No Prequal Personal Loan';
export const CFSB_PRIVACY_POLICY_MODAL = 'CFSB Privacy Policy Modal';
export const PRIVACY_POLICY_MODAL = 'Privacy Policy Modal';
export const TERMS_OF_USE_MODAL = 'Terms of Use Modal';
export const PREMIUM_DISCOUNT_TERMS_MODAL = 'Premium Disocunt Terms Modal';
export const PREMIUM_PLUS_TERMS_MODAL = 'Premium Plus Terms Modal';
export const LEARN_MORE_MODAL = 'Learn More Modal';
export const OPT_IN_PROGRAM_REMINDER_MODAL = 'Opt In Program Reminder Modal';
export const MARKETPLACE_PERSONAL_LOANS = 'Marketplace Personal Loans';
export const MARKETPLACE = 'Marketplace';
export const MARKETPLACE_OFFER_CAROUSEL = 'Offer Carousel';
export const INSURANCE_MARKETPLACE = 'Insurance';
export const HOMEOWNER_INSURANCE = 'Homeowner insurance';
export const RENTERS_INSURANCE = 'Renters insurance';
export const HOME_PADDER = 'Home Padder';
export const HOME_LOANS_TILES = 'Home Loans Information and Tools';
export const HOME_LOANS_MONTHLY_PAYMENT_CALCULATOR =
  'Monthly Payment Calculator';
export const HOME_LOANS_NEW = 'New Home Loans';
export const HOME_LOANS_ABOUT_REFINANCE = 'About Refinancing Mortgage';
export const HOME_LOANS_HOW_TO_REFINANCE_MORTGAGE =
  'How To Start Refinancing Mortgage';
export const HOME_LOANS_ABOUT_MORTGAGE = 'About New Mortgage';
export const HOME_LOANS_REFINANCE = 'Home Refinance';
export const HOME_LOANS_PURCHASE = 'Home Purchase';
export const HOME_LOANS_HOME_EQUITY = 'Home Equity';
export const HOME_LOANS_HOME_EQUITY_OFFERS = 'Home Equity Offers';
export const HOME_LOANS_REFINANCE_OFFERS = 'Home Loans Refinance Offers';
export const HOME_LOANS_NEW_HOME_OFFERS = 'Home Loans New Home Offers';
export const CREDIT_LIMIT_ACTION = 'Higher Credit Limit';
export const CREDIT_FOUNDATION_ACTION = 'Credit Foundation';
export const CREDIT_USAGE_ACTION = 'Credit Usage';
export const SELF_ACTION = 'Self Lender - Score Overtime';
export const NEGATIVE_MARKS_ACTION = 'Bad Payment History';
export const CREDIT_REPAIR_EXPERT_ACTION = 'Bad Payment History Score Increase';
export const NEW_CREDIT_REPAIR_ACTION = 'Credit Repair Expert';
export const NEW_REPORT_RENT_PAYMENTS_ACTION = 'Report Your Rent Payments';
export const NEW_PAYMENT_HISTORY_ACTION = 'Build Payment History';
export const POTENTIAL_GRADE_INCREASE_ACTION = 'Potential Grade Increase';
export const CREDIT_REPAIR_EXPERT_ACTION_TIP = 'Lexington law tip';
export const NEW_CC_SCORE_OVER_TIME_ACTION = 'New Card Score Over Time';
export const NEW_CARD_SCORE_INCREASE_ACTION = 'New Card Score Increase';
export const DEBT_PAY_OFF_CALCULATOR_ACTION = 'Debt Pay Off Calculator';
export const NEW_CARD_SCORE_INCREASE = 'New Card Score Increase';
export const CC_REWARDS_ACTION = 'Rewards';
export const CREDIT_CARD_DETAIL = 'Credit Card Detail';
export const OFFER_REVIEWS_MODAL = 'Offer Reviews';
export const SESAME_CASH_BALANCE = 'Sesame Cash Balance';
export const SESAME_CASH_UNENROLLED = 'Sesame Cash Unenrolled Module';
export const SESAME_CASH_UNENROLLED_OVERVIEW =
  'Sesame Cash Unenrolled Overview';
export const BUILDER_2_UNENROLLED_OVERVIEW = 'Builder 2.0 Unenrolled Overview';
export const BUILDER_2_UNENROLLED = 'Builder 2.0 Unenrolled';
export const LOGIN_AND_SECURITY = 'Login and Security Details';
export const TERMINATE_ACCOUNT = 'Terminate Account';
export const PERSONAL_LOAN_DETAIL = 'Personal Loan Detail';
export const AUTOLOAN_NO_RESULTS = 'Auto Loan No Results';
export const AUTOLOAN_DETAILS = 'Auto Loan Details';
export const AUTO_PURCHASE = 'Auto Purchase';
export const AUTO_LOAN_REFINANCE_OFFERS = 'Auto Refinance';
export const AUTO_LOAN_RATE_COMPARISON = 'Auto Loan Rate Comparison';
export const BUY_CAR_TIPS = 'Buy a Car Tips';
export const AUTO_LOAN_MARKETPLACE_REDIRECT = 'Auto Loan Marketplace Redirect';
export const AUTO_REFINANCE_MARKETPLACE_REDIRECT =
  'Auto Refinance Marketplace Redirect';
export const OTHER_AUTO_PURCHASE = 'Other Auto Purchase';
export const OTHER_AUTO_REFINANCE = 'Other Auto Refinance';
export const PROFILE_NOTIFICATION_SETTINGS = 'Notification Settings';
export const TRANSFER_TO_PARTNER = 'Transfer to Partner Interstitial';
export const CC_ALL_MARKETPLACE = 'Marketplace All';
export const DEBT_RELIEF_ACTION = 'Debt Relief';
export const PREQUAL_TERMS_MODAL = 'Prequal Terms & Conditions';
export const PREQUAL_COMMS_MODAL = 'Electronic Communications Consent';
export const CASH_BALANCE = 'Sesame Cash Balance';
export const CASH_ADD_MONEY = 'Add Money';
export const CASH_SET_PIN = 'Sesame Cash Set Card Pin';
export const CASH_DOWNLOAD = 'Sesame Cash Download App';
export const AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE =
  'Auto Insurance For Credit Score Increase';
export const AUTO_INSURANCE_FOR_NEW_AUTO_LOAN =
  'Auto Insurance For New Auto Loan';
export const AUTO_PURCHASE_ACTION = 'Auto Loan';
export const HOME_INSURANCE = 'Home Insurance For New Home';
export const PROFILE_CANCEL_PREMIUM_PLAN = 'Cancel Premium Plan';
export const PROFILE_CANCEL_PREMIUM_FREE_TRIAL_PLAN =
  'Cancel Premium Free Trial Plan';
export const AUTOMATIC_UPDATE_MODAL = 'Automatic score update delight';
export const SESSION_TIMEOUT_MODAL = 'Session timeout modal';
export const CASH_FUND_ACCOUNT_CTA = 'Sesame Cash Activation';
export const CASH_ACTIVATE_ACCOUNT = 'Cash Value Prop Carousel';
export const EXP_SESAME_CASH_TRANSFER = 'Sesame Cash Begin Plaid Transfer';
export const EXP_SESAME_CASH_TRANSFER_MANAGE_EXTERNAL =
  'Manage External Accounts';
export const EXP_SESAME_CASH_TRANSFER_PLAID_BALANCE_0 =
  'Sesame Cash Plaid Transfer Balance $0';
export const EXP_SESAME_CASH_TRANSFER_NSF = 'Sesame Cash Plaid Transfer NSF';
export const EXP_SESAME_CASH_TRANSFER_REMOVE_ACCOUNT =
  'Remove External Accounts';
export const EXP_SESAME_CASH_TRANSFER_TRANSFER_FUNDS =
  'Sesame Cash Transfer Funds';
export const EXP_SESAME_CASH_TRANSFER_B2C = 'Sesame Cash B2C Transfer Success';
export const EXP_SESAME_CASH_TRANSFER_C2B = 'Sesame Cash C2B Transfer Success';
export const USER_QUESTIONAIRE_MODAL = 'Overview Questionnaire Modal';
export const USER_QUESTIONAIRE_MODAL_GOALS = 'Overview Questionnaire Goals';
export const USER_QUESTIONAIRE_MODAL_INTRO = 'Overview Questionnaire Intro';
export const USER_QUESTIONAIRE_MODAL_SITUATION =
  'Overview Questionnaire Situation';
export const FEATURED_AD = 'Featured Ad';
export const NATIVE_AD = 'Native Ads';
export const BUILDER_BALANCE_MODULE = 'Builder Balance Module';
export const INVISIBLE_CASH_ENROLL_CARD_UNVERIFIED =
  'Invisible Cash Enroll Card - Unverified';
export const INVISIBLE_CASH_ENROLL_CARD_FUNDING = 'Invisible Cash Funding Card';
export const INVISIBLE_CASH_ENROLL_CARD_ENROLLED = 'Invisible Cash Enroll Card';
export const INVISIBLE_CASH_ENROLL_CARD_PENDING =
  'Invisible Cash Enroll Card - Pending';
export const INVISIBLE_CASH_ENROLL_CARD_FAILED =
  'Invisible Cash Enroll Card - Failed';

// ocfs
export const OCF_INCREASE_CREDIT_LIMIT_OFFERS =
  'OCF - Increase Total Credit Limit Offers';
export const OCF_USERS_LIKE_YOU_OFFERS = 'OCF - Members Like You Offers';
export const OCF_WHATS_CHANGED_OFFERS = "OCF - What's Changed Offers";
export const OCF_CARD_LIMITS_OFFERS = 'OCF - Card Limits Offers';
export const OCF_ALL_PRODUCTS_OFFERS = 'OCF - All Products Offers';
export const OCF_CC_BORROWING_POWER_OFFERS = 'OCF - CC Borrowing Power Offers';
export const OCF_REFER_A_FRIEND_OFFERS = 'OCF - Refer a Friend Offers';
export const OCF_INCREASE_CREDIT_LIMIT_TRADELINES_OFFERS =
  'OCF - Increase Credit Limit By Tradelines Offers';
export const OCF_REBALANCE_CREDIT_UTILIZATION_OFFERS =
  'OCF - Rebalance Credit Utilization Offers';
export const OCF_CC_REWARDS_CATEGORIES_OFFERS =
  'OCF - CC Rewards Categories Offers';
export const OCF_CREDIT_FOUNDATION_OFFERS = 'OCF - Credit Foundation Offers';
export const OCF_CARD_COMPARISON_OFFERS = 'OCF - Card Comparison Offers';
export const OCF_HOLIDAY_SPENDING_OFFERS = 'OCF - Holiday Spending Offers';
export const OCF_CC_UTILIZATION_OFFERS = 'OCF - CC Utilization Offers';
export const OCF_OTHER_MEMBER_INTERESTS_OFFERS =
  'OCF - Other Members Card Interest Offers';
export const OCF_BECOME_ELIGIBLE_FOR_AWESOME_THINGS_OFFERS =
  'OCF - Become Eligible For Awesome Things Offers';
export const OCF_CREDIT_FOUNDATION_CC_MODULE =
  'OCF - Credit Foundation CC Offers';
export const OCF_TIPS_MODULE = 'OCF - Tips Offers';
export const NEW_CARD_SCORE_OVER_TIME = 'New Card Score Overtime';
export const OCF_CREDIT_FOUNDATION_PL_MODULE =
  'OCF - Credit Foundation Personal Loan Offers';
export const AUTO_INSURANCE_OCF = 'OCF - Auto Insurance Offers';
export const LIFE_INSURANCE_OCF = 'OCF - Life Insurance Offers';
export const BALANCE_TRANSFER_OCF = 'OCF - Balance Transfer Offers';
export const PRE_APPROVED_OFFERS_OCF = 'OCF - Pre Approved Offers';
export const HOME_REFINANCE_OCF = 'OCF - Home Refinance Offers';
export const HOME_PURCHASE_OCF = 'OCF - Home Purchase Offers';
export const HOMEOWNER_INSURANCE_OCF = 'OCF - Homeowner insurance';
export const RENTERS_INSURANCE_OCF = 'OCF - Renters insurance';
export const AUTO_REFINANCE_OCF = 'OCF - Auto Refinance Offers';
export const AUTO_PURCHASE_OCF = 'OCF - Auto Purchase Offers';
export const HEADER_TEMPLATE_OCF = 'OCF - Template Header';
export const OFFERS_TEMPLATE_OCF = 'OCF - Template Offers';
// ocfs

export const CREDIT_TOOLS = 'Credit Tools';
export const PRE_APPROVED_OVERVIEW_PLACEMENT = 'PreApproved Overview Placement';
export const VIEW_AGREEMENT_MODAL = 'View Agreement Modal';
export const SUGGESTED_CREDIT_CARDS = 'Suggested Credit Cards';
export const RENT_PAYMENT_DASHBOARD = 'Rent Payment Dashboard';
export const PREMIUM_BANNER = 'Premium Banner';
export const PREMIUM_FEATURES = 'Premium Features';
export const PREMIUM_UPSELL = 'Premium Upsell';
export const PARTNER_TEXT_DISCLAIMER = 'Partner Text Disclaimer';
export const PAYMENT_HISTORY = 'Payment History';
export const AUTO_REFINANCE = 'Auto Refinance';
export const SELF_REPORTED_INCOME = 'Self Reported Income';
export const INCREASE_SCORE_TIPS = 'Low Score Increase Tip';
export const MARKETPLACE_UNLOCK_CATEGORIES =
  'Marketplace Unlock more categories';
export const PREMIUM_AOOP_TILE = 'Premium AOOP Tile';
export const WEB_BOOSTER_PROMO = 'Web Booster Promo';
export const CREDIT_CARD_COMPARISON = 'Credit Card Comparison';
export const CURATED_CREDIT_CARD_CATEGORIES = 'Credit Card Categories Carousel';
export const SESAME_CASH_FRAUD_BLOCKED = 'Sesame Cash Fraud Blocked';
export const LIFE_INSURANCE = 'Life Insurance';
export const CASH_PAGE_TEMPORARILY_NOT_AVAILABLE = 'Sesame Cash Outage\n';
export const AUTO_INSURANCE = 'Auto Insurance';

export const ACCUMULATE_CASH_PROMO = 'Sesame Cash Unenrolled Module - Big';
export const ACCUMULATE_CASH_PROMO_UNFUNDED =
  'Sesame Cash Unfunded Module - Big';
export const AUTO_DD_SUCCESS = 'Direct Deposit Switch - Success Page';
export const AUTO_DD_FAILURE = 'AUTO_DD_FAILURE';
export const AUTO_DD_PENDING = 'Direct Deposit Switch - Processing Page';
export const DIRECT_DEPOSIT_AUTOMATIC = 'Automatic Direct Deposit';
export const DIRECT_DEPOSIT_MANUAL = 'Manual Direct Deposit';
export const KYC_SOFT_FAIL = 'Sesame Cash Fail KYC Soft';
export const KYC_HARD_FAIL = 'Sesame Cash Fail KYC Hard';
export const PREMIUM_PAYMENT_PENDING_CHECKOUT = 'Fix payment - checkout';
export const PREMIUM_PAYMENT_PENDING_SUCCESS = 'Fix payment - success';
export const MARKETPLACE_PRE_APPROVED_BANNER =
  'Highest Approval Odds Marketplace Banner';
export const TU3B_REPORT_PRINT_VERSION = 'TU3B Report Print Version';
export const SCORE_FACTORS = 'Score Factors';
export const PREMIUM_BANNER_TOP = 'Premium Banner Top';
export const CREDIT_BUILDER_2_MODULE = 'Credit Builder 2.0 Module';
export const OFFERS_DETAILS_MODAL = 'Offers Details Modal';
export const PREMIUM_ONBOARD_MODAL = 'Premium Onboard Modal';
export const FACTOR_NOTIFICATIONS = 'Factor Notifications Module';
export const CREDIT_SCORE_DIAL = 'Credit Dial';
export const ENGAGEMENT_OFFERS = 'Engagement Offers';
export const EVENT_ENGAGEMENT = 'Event Engagement Module';
export const REVIEW_ENTRY_POINTS = 'REVIEW_ENTRY_POINTS';
export const CREDIT_CARD_DEBT_PAYOFF = 'Pay Off Card Debt';
export const PRESCREEN_OFFERS = 'PreScreen Offers';

export const CREDIT_MANAGER_UPSELL = 'Credit Manager Upsell';
export const CATCH_ME_BEFORE_I_FALL = 'Catch Me Before I Fall';
export const DELINQUENT = 'Delinquent';
export const PAYMENT_REPORTING = 'Payment Due';
export const GRADE_CHANGE = 'Grade Change';
export const CREDIT_USAGE = 'Credit Usage';
export const CREDIT_BUILDER_NOT_ENROLLED = 'Credit Builder Not Enrolled';
export const RENT_REPORTING_NOT_ENROLLED = 'Rent Reporting Not Enrolled';
export const INQUIRIES_FALL_OFF_MODULE = 'Inquiry Fall';
export const POTENTIAL_SCORE_SIMULATOR = 'Potential - Score Simulation';

export const FACTOR_TIPS_OFFERS = 'Factor tips Offers';

import { getPublicSiteUrl, getLoginUrl } from 'helpers/env';
import { goToUrl, getGetParam } from 'helpers/domHelper';
import { getRefCode } from 'helpers/localstorage';

export const getOcfBaseUrl = () => {
  return '/ocf';
};

export const getCreditCardOcfUrl = () => {
  return `${getOcfBaseUrl()}/credit-cards`;
};

export const redirectToDefaultPage = function (
  expOcfRedirectionVariationActive,
) {
  let url = '';

  if (expOcfRedirectionVariationActive) {
    url = getLoginUrl('?redirectUrl=%2Factions');
  } else {
    let ref = getGetParam('ref');

    if (!ref) {
      ref = getRefCode();
    }

    url = ref
      ? getPublicSiteUrl() + '/credit-cards/best/?ref=' + ref
      : getPublicSiteUrl() + '/credit-cards/best/';
  }

  goToUrl(url);
};

export const getSimulationById = function (simId, simulationArray) {
  if (simulationArray != null) {
    let simulation = simulationArray.find(sim => sim.simulationName === simId);
    if (simulation != null) {
      return simulation;
    }
  }
  return null;
};

export const getCreditUsage = function (
  totalUsedCredit,
  totalCreditLimit,
  withOverLimit,
) {
  if (totalUsedCredit == null) {
    return 0;
  }
  if (totalCreditLimit == null) {
    totalCreditLimit = totalUsedCredit;
  }

  // Calculate the credit usage percentage and the other loans involved on that calculation
  let totalPercent100 = 100;
  let percentLimit = 200;
  let creditUsagePercent =
    totalCreditLimit > 0
      ? (
          (totalUsedCredit * totalPercent100) /
          Math.round(totalCreditLimit)
        ).toFixed(0)
      : 0;

  // If credit is over limit, usage should be 200%
  if (!withOverLimit && creditUsagePercent > percentLimit) {
    creditUsagePercent = percentLimit;
  }

  return creditUsagePercent;
};

export const getGrade = function (creditLimit, creditUsagePercentage) {
  if (creditLimit != null && creditLimit > 0) {
    if (creditUsagePercentage >= 0 && creditUsagePercentage <= 10) {
      return 'A';
    } else if (creditUsagePercentage > 10 && creditUsagePercentage <= 30) {
      return 'B';
    } else if (creditUsagePercentage > 30 && creditUsagePercentage <= 50) {
      return 'C';
    } else if (creditUsagePercentage > 50 && creditUsagePercentage <= 70) {
      return 'D';
    } else if (creditUsagePercentage > 70) {
      return 'F';
    }
  }
  return 'F';
};

export function getUserId(location) {
  const queryString = location.search;
  let userId = '';
  if (queryString != null && queryString !== '') {
    const urlParams = new URLSearchParams(queryString);
    userId = urlParams.get('fobs');
  }
  const uuidV4Regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidV4Regex.test(userId) ? userId : null;
}

export function getHighestLimit(creditCardOffers) {
  let highestLimit = 0;
  if (creditCardOffers) {
    let offers = creditCardOffers.modules
      ? creditCardOffers.modules[0].offers
      : creditCardOffers;
    offers.forEach((item, index) => {
      if (item.predictedCreditLimit > highestLimit) {
        highestLimit = item.predictedCreditLimit;
      }
    });
  }
  return highestLimit;
}

export function filterOffersByVertical(offers, vertical) {
  let filteredOffers = null;
  if (offers) {
    const unwrapedOffers = offers.modules[0].offers;
    filteredOffers = unwrapedOffers.filter(
      offer => offer.offerType === vertical,
    );
  }
  return filteredOffers;
}

export function filterUnWrapedOffersByVertical(offers, vertical) {
  let filteredOffers = null;
  if (offers?.length > 0) {
    filteredOffers = offers.filter(offer => offer.offerType === vertical);
  }
  return filteredOffers;
}

export const filterOffersByCategories = (offers, category) => {
  let filteredOffers = null;
  if (offers?.length > 0) {
    filteredOffers = offers.filter(offer =>
      offer.categories.includes(category),
    );
  }
  return filteredOffers;
};

export function setOcfRewardsModule(offersModule) {
  return setOcfModuleName(offersModule, 'OCF_REWARDS_CARDS');
}

export function setOcfModuleName(offersModule, moduleName) {
  if (offersModule) {
    offersModule.modules[0].module = moduleName;
  }
  return offersModule;
}

export function filterOfferById(offers, id) {
  let filteredOffer = null;
  if (offers) {
    const filteredOfferArray = offers.filter(offer => {
      return (
        (offer.offerType === 'CREDIT_CARD' && offer.creditCardId === id) ||
        (offer.offerType === 'PERSONAL_LOAN' && offer.personalLoanId === id) ||
        (offer.offerType === 'AUTO_INSURANCE' && offer.impressionId === id) ||
        (offer.offerType === 'LIFE_INSURANCE' && offer.impressionId === id) ||
        (offer.offerType === 'HOME_INSURANCE' && offer.impressionId === id)
      );
    });
    filteredOffer =
      filteredOfferArray.length > 0 ? filteredOfferArray[0] : null;
  }
  return filteredOffer;
}

export function isOcfPage(location) {
  return (
    location &&
    (location.pathname.startsWith('/ocf') ||
      location.pathname.startsWith('/public/unsubscribe'))
  );
}

export function wrapOcfOffers(offer) {
  return { modules: [{ offers: offer }] };
}

export function filterOffersByCreditLimit(ccOffersModule) {
  let filteredOffers = null;
  if (ccOffersModule) {
    filteredOffers = ccOffersModule.filter(offer => !offer.hideCreditLimit);
    return filteredOffers;
  }
  return filteredOffers;
}

export function filterUnWrapedOffersByCreditLimit(offers) {
  let filteredOffers = null;
  if (offers?.length > 0) {
    filteredOffers = offers.filter(offer => !offer.hideCreditLimit);
  }
  return filteredOffers;
}

export function maskEmail(email) {
  if (!email) return '****@***.***';
  let pos = email.indexOf('@');
  let sb = '';
  let emailShort = email.substring(0, pos);
  if (emailShort.length > 4) {
    let emailFirst2 = emailShort.substring(0, 2);
    let emailLast2 = emailShort.substring(emailShort.length - 2);
    for (let i = 0; i < emailShort.length - 4; i++) {
      sb += '*';
    }
    return emailFirst2 + sb + emailLast2 + email.substring(pos);
  } else if (emailShort.length <= 4 && emailShort.length > 2) {
    let emailFirst2 = emailShort.substring(0, 2);
    for (let i = 0; i < emailShort.length - 2; i++) {
      sb += '*';
    }
    return emailFirst2 + sb + email.substring(pos);
  } else {
    return email;
  }
}

export function getRefEmailId() {
  const refParam = getRefCode();
  const emailId = refParam ? refParam.split('_')[0] : '';
  return emailId;
}

/**
 * Utility function to check which property in experiment rsponse should be
 * use from OCF experiment endpoint. Might not to revisit to refactor as it creates
 * dependecy on variation names
 *
 */
export function getOcfExperimentPropertyName(ocfName, variation) {
  switch (variation) {
    case 'VAR1':
    case 'VAR_1':
      return ocfName + '_EXPERIMENT';
    case 'VAR2':
    case 'VAR_2':
      return ocfName + '_EXPERIMENT_1';
    case 'VAR3':
    case 'VAR_3':
      return ocfName + '_EXPERIMENT_2';
    default:
      return ocfName;
  }
}

export const getDebtAccountHighestLimit = debtAccounts => {
  let highestLimit = 0;
  if (debtAccounts) {
    debtAccounts
      .filter(account => account.status === 'Open')
      .forEach(account => {
        highestLimit += account.creditLimit;
      });
  }

  return highestLimit;
};

export const filterPreApprovedOffers = offers => {
  const nonPreApprovedOffers = [];
  const preApprovedOffers = offers.filter(offer => {
    if (offer.approvalOdds === 'PRE_APPROVED') {
      return true;
    } else {
      nonPreApprovedOffers.push(offer);
      return false;
    }
  });

  return { nonPreApprovedOffers, preApprovedOffers };
};

import {
  buildEnrollPremium,
  buildSubscriptionBillingEndpoint,
  buildCancelPremiumSubscription,
  buildCancelPremiumSubscriptionImmediate,
  buildCouponRedeemEndpoint,
  buildPremiumSubscriptionReactivationEndpoint,
  buildValidateCouponCode,
  buildVerifyPromotionEligibilityEndpoint,
  buildCancelPlusSubscriptionAndDeleteAccount,
  buildCancelPlusSubscriptionSurvey,
} from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from './service_helpers';

export async function fetchSubscriptionInfo() {
  const requestOptions = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(buildEnrollPremium(), requestOptions);
}

export async function postEnrollPremium(
  recurlyToken,
  subscriptionPlan,
  promoCode,
  reactivate = false,
  consentedTo,
  direct2Premium,
  extraParams,
) {
  const body = {
    recurlyToken,
    subscriptionPlan,
    promoCode,
    reactivate,
    consentedTo,
    direct2Premium,
    ...extraParams,
  };
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
    timeout: 60000,
  });

  return standardizedFetchHandler(buildEnrollPremium(), requestOptions);
}

export async function putUpgradePlus(recurlyToken, data) {
  const body = { recurlyToken, ...data };
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body,
  });
  return standardizedFetchHandler(buildEnrollPremium(), requestOptions);
}

export async function postCancelPremiumSubscription(
  cancellationDate,
  answer,
  comment,
  author,
) {
  const body = { cancellationDate, answer, comment, author };
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });
  return standardizedFetchHandler(
    buildCancelPremiumSubscription(),
    requestOptions,
  );
}

export async function postCancelPremiumSubscriptionImmediately(
  cancellationDate,
  answer,
  comment,
  author,
  refundType,
) {
  const body = { cancellationDate, answer, comment, author, refundType };
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });
  return standardizedFetchHandler(
    buildCancelPremiumSubscriptionImmediate(),
    requestOptions,
  );
}

export async function patchBillingInfo(recurlyToken, address) {
  const body = { recurlyToken, ...address };
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PATCH',
    body,
  });

  return standardizedFetchHandler(
    buildSubscriptionBillingEndpoint(),
    requestOptions,
  );
}

export async function fetchBillingInfo(type) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString({ type });

  return standardizedFetchHandler(
    buildSubscriptionBillingEndpoint() + queryString,
    requestOptions,
  );
}

export async function postCouponRedeem(couponCode) {
  const body = { couponCode };
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });

  return standardizedFetchHandler(buildCouponRedeemEndpoint(), requestOptions);
}

export async function putPremiumSubscriptionReactivation(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body,
  });

  return standardizedFetchHandler(
    buildPremiumSubscriptionReactivationEndpoint(),
    requestOptions,
  );
}

export async function postValidateCoupon(subscriptionPlan, couponCode) {
  const body = { subscriptionPlan, couponCode };
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });

  return standardizedFetchHandler(buildValidateCouponCode(), requestOptions);
}

export async function postCancelPlusSubscription(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });
  return standardizedFetchHandler(
    buildCancelPremiumSubscription(),
    requestOptions,
  );
}

export async function fetchVerifyPromotionEligibility(type) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(type);

  return standardizedFetchHandler(
    buildVerifyPromotionEligibilityEndpoint() + queryString,
    requestOptions,
  );
}

export async function postCancelPlusSubscriptionAndDeleteAccount(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });
  return standardizedFetchHandler(
    buildCancelPlusSubscriptionAndDeleteAccount(),
    requestOptions,
  );
}

export async function postCancelPlusSubscriptionSurvey(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });
  return standardizedFetchHandler(
    buildCancelPlusSubscriptionSurvey(),
    requestOptions,
  );
}
